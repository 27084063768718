import styled from "styled-components"

const IconClose = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
  &::after,
  &::before {
    position: absolute;
    top: -20%;
    margin-left: -${(props) => (props.length || 1) / 2}px;
    left: 50%;
    border-radius: ${(props) => (props.length || 1) * 4}px;
    content: "";
    display: block;
    height: 141.42%; /* sqrt of 2 */
    overflow: hidden;
    width: ${(props) => props.length || 1}px;
    background-color: currentColor;
  }
  &::after {
    transform-origin: center;
    transform: rotate(45deg);
  }
  &::before {
    transform-origin: center;
    transform: rotate(-45deg);
  }
`

export default IconClose
