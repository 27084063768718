import React from "react"

// const IconPlus = styled.div`
//   position: relative;
//   overflow: visible;
//   &::after,
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0%;
//     left: 50%;
//     margin-left: -${(props) => (props.length || 1) / 2}px;
//     border-radius: ${(props) => (props.length || 1) * 4}px;
//     display: block;
//     height: 100%;
//     overflow: hidden;
//     width: ${(props) => props.length || 1}px;
//     background-color: currentColor;
//   }
//   &::after {
//     transform-origin: center;
//   }
//   &::before {
//     transform-origin: center;
//     transform: rotate(90deg);
//   }
// `

const IconPlus = (props) => {
  return (
    <svg
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 13.7998C7 13.3584 7.35742 13 7.80078 13H13V7.7998C13 7.58008 13.0879 7.38086 13.2324 7.23535C13.377 7.08984 13.5781 7 13.8008 7H14.1992C14.6426 7 15 7.3584 15 7.7998V13H20.1992C20.4531 13 20.6777 13.1172 20.8242 13.2998C20.9355 13.4365 21 13.6104 21 13.7998V14.2002C21 14.6416 20.6426 15 20.1992 15H15V20.2002C15 20.373 14.9453 20.5322 14.8516 20.6631C14.707 20.8662 14.4688 21 14.1992 21H13.8008C13.3574 21 13 20.6416 13 20.2002V15H7.80078C7.54883 15 7.32422 14.8838 7.17773 14.7031C7.06641 14.5654 7 14.3906 7 14.2002V13.7998Z"
        fill="#fff"
      />
      <path
        clipRule="evenodd"
        d="M1 14C1 6.82031 6.82031 1 14 1C21.1797 1 27 6.82031 27 14C27 21.1797 21.1797 27 14 27C6.82031 27 1 21.1797 1 14ZM14 3C7.92578 3 3 7.9248 3 14C3 20.0752 7.92578 25 14 25C20.0742 25 25 20.0752 25 14C25 7.9248 20.0742 3 14 3Z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default IconPlus
