import React, { useState } from "react"
import {
  ANNUAL_PLAN_SUFFIX,
  APPOINTMENTS_FEATURE,
  CONTACTS_FEATURE,
  FAQS_FEATURE,
  FREE_PLAN,
  GROUP_EVENTS_FEATURE,
  LEGACY_FREE_PLAN,
  LINKS_FEATURE,
  PREMIUM_PLAN,
  PRO_PLAN,
  // PRODUCTS_FEATURE,
  PRODUCTS_SERVICES_FEATURE,
  // SERVICES_FEATURE,
  // SMS_FEATURE,
  STARTER_PLAN,
  TRANSACTION_FEE_FEATURE,
  // WELLNESS_FEATURE,
} from "../utils/constants"

import {
  // getPlanFeaturesByProduct,
  getUniquePlanFeatureLists,
} from "../utils/pricing"

const plans = [
  {
    key: FREE_PLAN,
    index: 0,
    name: "Free",
    description: "Try all our features for FREE",
    accentColor: "var(--sparkle-lightblue)",
    inactiveAccentColor: "var(--sparkle-lightblue-opacity)",
    price: {
      monthly: "0",
      annual: "0",
      subtext: "✨",
    },
    features: [
      {
        key: LINKS_FEATURE,
        product: "Site",
        name: "Site",
        showDescriptionOnly: false,
        maxLimit: null,
        description: "links & content",
      },
      // {
      //   key: PRODUCTS_SERVICES_FEATURE,
      //   product: "Site",
      //   name: "Products & Services",
      //   showDescriptionOnly: true,
      //   maxLimit: null,
      //   description: "Sell products & services",
      // },
      {
        key: APPOINTMENTS_FEATURE,
        product: "Scheduler",
        name: "Appointment Type",
        maxLimit: 1,
        description: "active appointment type (1:1 meeting OR group event)",
      },
      {
        key: GROUP_EVENTS_FEATURE,
        product: "Scheduler",
        name: "Group Events",
        maxLimit: 0,
        description: "group events",
      },
      {
        key: FAQS_FEATURE,
        product: "Assistant",
        name: "FAQs",
        maxLimit: 2,
        preText: "Assistant answers",
        description: "FAQs",
      },
      {
        key: CONTACTS_FEATURE,
        product: "Assistant",
        name: "Contacts",
        maxLimit: 100,
        // preText: "Store",
        description: "contacts in CRM",
      },

      // {
      //   key: SMS_FEATURE,
      //   product: "Assistant",
      //   name: "SMS",
      //   maxLimit: 10,
      //   // preText: "Send",
      //   description: "marketing texts/mo",
      // },
      {
        key: TRANSACTION_FEE_FEATURE,
        product: "Site",
        name: "fee",
        maxLimit: "5%",
        description: "transaction fee*",
        footnote:
          "plus Stripe’s processing fee of 2.9% + 30¢ per successful card charge",
      },
    ],
  },
  {
    key: STARTER_PLAN,
    index: 1,
    name: "Starter",
    description: "Share content, get paid",
    accentColor: "var(--sparkle-yellow)",
    inactiveAccentColor: "var(--sparkle-yellow-opacity)",
    price: {
      monthly: "9",
      annual: "99",
      subtext: "1 month free on annual plan",
    },
    features: [
      // {
      //   key: LINKS_FEATURE,
      //   product: "Site",
      //   name: "Share links + content",
      //   showDescriptionOnly: true,
      //   maxLimit: null,
      //   description: "Share links + content",
      // },
      // {
      //   key: PRODUCTS_SERVICES_FEATURE,
      //   product: "Site",
      //   name: "Products & Services",
      //   showDescriptionOnly: true,
      //   maxLimit: null,
      //   description: "Sell products & services",
      // },
      {
        key: APPOINTMENTS_FEATURE,
        product: "Scheduler",
        name: "Appointment Types",
        maxLimit: 2,
        description: "Appointment types (1:1 meeting & group event)",
      },

      {
        key: FAQS_FEATURE,
        product: "Assistant",
        name: "FAQs",
        maxLimit: 10,
        preText: "Assistant answers",
        description: "FAQs",
      },
      // {
      //   key: SMS_FEATURE,
      //   product: "Assistant",
      //   name: "SMS",
      //   maxLimit: 100,
      //   // preText: "Send",
      //   description: "marketing texts/mo",
      // },
      {
        key: CONTACTS_FEATURE,
        product: "Assistant",
        name: "Contacts",
        maxLimit: 250,
        // preText: "Store",
        description: "contacts in CRM",
      },
      {
        key: TRANSACTION_FEE_FEATURE,
        product: "Site",
        name: "fee",
        maxLimit: "4%",
        description: "transaction fee*",
        footnote:
          "plus Stripe’s processing fee of 2.9% + 30¢ per successful card charge",
      },
    ],
  },
  {
    key: PRO_PLAN,
    index: 2,
    name: "Pro",
    description: "Use AI to save time",
    accentColor: "var(--sparkle-green)",
    inactiveAccentColor: "var(--sparkle-green-opacity)",
    monthPrice: "25",
    yearPrice: "300",
    price: {
      monthly: "29",
      annual: "300",
      subtext: "Save $48 on annual plan",
    },
    features: [
      // {
      //   key: LINKS_FEATURE,
      //   product: "Site",
      //   name: "Site",
      //   showDescriptionOnly: true,
      //   maxLimit: null,
      //   description: "Share links + content",
      // },
      // {
      //   key: PRODUCTS_SERVICES_FEATURE,
      //   product: "Site",
      //   name: "Products & Services",
      //   showDescriptionOnly: true,
      //   maxLimit: null,
      //   description: "Sell products & services",
      // },
      {
        key: APPOINTMENTS_FEATURE,
        product: "Scheduler",
        name: "Appointment Types",
        maxLimit: null,
        useDescription: true,
        // preText: "Schedule",
        description: "1:1 appointments & group events",
      },
      {
        key: FAQS_FEATURE,
        product: "Assistant",
        name: "FAQs",
        maxLimit: 30,
        preText: "Assistant answers",
        description: "FAQs",
      },
      // {
      //   key: SMS_FEATURE,
      //   product: "Assistant",
      //   name: "SMS",
      //   maxLimit: 300,
      //   description: "marketing texts/mo",
      // },
      {
        key: CONTACTS_FEATURE,
        product: "Assistant",
        name: "Contacts",
        maxLimit: 500,
        // preText: "Store",
        description: "contacts in CRM",
      },
      {
        key: TRANSACTION_FEE_FEATURE,
        product: "Site",
        name: "fee",
        maxLimit: "3%",
        description: "transaction fee*",
        footnote:
          "plus Stripe’s processing fee of 2.9% + 30¢ per successful card charge",
      },
    ],
  },
  {
    key: PREMIUM_PLAN,
    index: 3,
    name: "Premium",
    description: "Unlimited bookings & contacts",
    accentColor: "var(--sparkle-brightblue)",
    inactiveAccentColor: "var(--sparkle-brightblue-opacity)",
    price: {
      monthly: "49",
      annual: "540",
      subtext: "Save $48 on annual plan",
    },
    features: [
      // {
      //   key: LINKS_FEATURE,
      //   product: "Site",
      //   name: "Site",
      //   showDescriptionOnly: true,
      //   maxLimit: null,
      //   description: "Share links + content",
      // },
      // {
      //   key: PRODUCTS_SERVICES_FEATURE,
      //   product: "Site",
      //   name: "Products & Services",
      //   showDescriptionOnly: true,
      //   maxLimit: null,
      //   description: "Sell products & services",
      // },

      {
        key: APPOINTMENTS_FEATURE,
        product: "Scheduler",
        name: "Appointment Types",
        maxLimit: null,
        description: "1:1 appointments & group events",
      },
      {
        key: FAQS_FEATURE,
        product: "Assistant",
        name: "FAQs",
        maxLimit: null,
        preText: "Assistant answers",
        description: "FAQs",
      },
      // {
      //   key: SMS_FEATURE,
      //   product: "Assistant",
      //   name: "marketing texts/mo",
      //   maxLimit: 500,
      //   // preText: "Send",
      //   description: "marketing texts/mo",
      // },
      {
        key: CONTACTS_FEATURE,
        product: "Assistant",
        name: "Contacts",
        maxLimit: null,
        description: "contacts in CRM",
      },
      {
        key: TRANSACTION_FEE_FEATURE,
        product: "Site",
        name: "fee",
        maxLimit: "2%",
        description: "transaction fee*",
        footnote:
          "plus Stripe’s processing fee of 2.9% + 30¢ per successful card charge",
      },
    ],
  },
]

const featuresTable = [
  {
    featureHeading: "Sparkle Site (Your Personal Landing Page)",
    featureList: [
      {
        featureName: "Share links",
        featurePlans: [true, true, true, true],
      },
      {
        featureName: "Collect leads",
        featurePlans: [true, true, true, true],
      },
      {
        featureName: "Get paid for products & services",
        featurePlans: ["5% fee", "4% fee", "3% fee", "2% fee"],
      },
      {
        featureName: "Collect tips or donations",
        featurePlans: ["5% fee", "4% fee", "3% fee", "2% fee"],
      },
      {
        featureName: "Encourage reviews",
        featurePlans: [true, true, true, true],
      },
    ],
  },
  {
    featureHeading: "Sparkle Scheduler",
    featureList: [
      {
        featureName: "Appointment Types (1:1 meetings OR group events)",
        featurePlans: ["1", "2", "Unlimited", "Unlimited"],
      },
    ],
  },
  {
    featureHeading: "Sparkle AI Assistant",
    featureList: [
      {
        featureName: "Sparkle AI answers FAQs",
        featurePlans: ["2", "10", "30", "Unlimited"],
      },
      // {
      //   featureName: "Text Message Marketing",
      //   featurePlans: ["10", "100", "300", "500"],
      // },
      {
        featureName: "Sparkle AI auto-upsells",
        featurePlans: [true, true, true, true],
      },
    ],
  },
  {
    featureHeading: "Sparkle Care",
    featureList: [
      {
        featureName: "Guided meditation app",
        featurePlans: [true, true, true, true],
      },
      {
        featureName: "Meditation reminders",
        featurePlans: [true, true, true, true],
      },
      {
        featureName: "Digital gratitude journal",
        featurePlans: [true, true, true, true],
      },
      {
        featureName: "Community & Learning",
        featurePlans: [true, true, true, true],
      },

      {
        featureName: "Personalized Support",
        featurePlans: [false, true, true, true],
      },
    ],
  },
]

function usePricingPlans() {
  // TODO: retrieve pricing plans from database api
  // hardcoded for now

  // default selected plan is 'pro', index 2
  const [selectedPlan, setSelectedPlan] = useState(FREE_PLAN)
  const selectedPlanIndex = plans.find(
    (plan) => plan.key === selectedPlan
  ).index

  const [isAnnual, setIsAnnual] = useState(false)

  // unique features by plan
  const uniqueFeaturesByPlan = getUniquePlanFeatureLists(plans)

  const selectedPlanColor = plans.find(
    (plan) => plan.key === selectedPlan
  ).accentColor
  const selectedPlanInactiveColor = plans.find(
    (plan) => plan.key === selectedPlan
  ).inactiveAccentColor

  return {
    plans,
    features: uniqueFeaturesByPlan,
    featuresTable,
    isAnnual,
    setIsAnnual,
    selectedPlan,
    selectedPlanIndex,
    selectedPlanColor,
    selectedPlanInactiveColor,
    setSelectedPlan,
  }
}

export default usePricingPlans
