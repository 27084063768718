import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"

import useSignUpContext from "../../hooks/useSignUpContext"

import {
  ARTS_OPTION,
  FOODDRINK_OPTION,
  HEALTH_OPTION,
  OTHER_OPTION,
  RETAIL_OPTION,
  SERVICES_OPTION,
  CUSTOM_OPTION,
} from "../../utils/constants"

import IconArts from "../../icons/IconArts"
import IconFood from "../../icons/IconFood"
import IconPlus from "../../icons/IconPlus"
import IconStore from "../../icons/IconStore"
import IconSupport from "../../icons/IconSupport"
import IconWellness from "../../icons/IconWellness"
import IconB2B from "../../icons/IconB2B"
import IconB2C from "../../icons/IconB2C"

const businessTypes = [
  {
    key: ARTS_OPTION,
    label: "Arts & Entertainment",
    icon: (
      <IconArts
        style={{
          height: "clamp(1em, 2rem, 2.5rem)",
          fill: "#FFFFFF",
        }}
      />
    ),
  },
  {
    key: FOODDRINK_OPTION,
    label: "Food & Drink",
    icon: (
      <IconFood
        style={{
          height: "clamp(1em, 2rem, 2.5rem)",
          fill: "#FFFFFF",
        }}
      />
    ),
  },
  {
    key: HEALTH_OPTION,
    label: "Health & Wellness",
    icon: (
      <IconWellness
        style={{
          height: "clamp(1em, 2rem, 2.5rem)",
          fill: "#FFFFFF",
        }}
      />
    ),
  },
  {
    key: RETAIL_OPTION,
    label: "Retail",
    icon: (
      <IconStore
        style={{
          height: "clamp(1em, 2rem, 2.5rem)",
          fill: "#FFFFFF",
        }}
      />
    ),
  },
  {
    key: SERVICES_OPTION,
    label: "Services",
    icon: (
      <IconSupport
        style={{
          height: "clamp(1em, 2rem, 2.5rem)",
          fill: "#FFFFFF",
        }}
      />
    ),
  },
  {
    key: OTHER_OPTION,
    label: "Other",
    icon: (
      <IconPlus
        style={{
          height: "clamp(1em, 2rem, 2.5rem)",
          fill: "#FFFFFF",
        }}
      />
    ),
  },
]

const BusinessTypeOptions = () => {
  const [showAdditionalServiceIcons, setShowAdditionalServiceIcons] =
    useState(false)
  const [showCustomBusinessType, setShowCustomBusinessType] = useState(false)
  const [selectedBusinessType, setSelectedBusinessType] = useState(ARTS_OPTION)
  const { businessType, setBusinessType } = useSignUpContext()

  function handleAdditionalOptions(selectedBusinessType) {
    switch (true) {
      case selectedBusinessType.startsWith(OTHER_OPTION):
      case selectedBusinessType.startsWith(CUSTOM_OPTION):
        setShowCustomBusinessType(true)
        setShowAdditionalServiceIcons(false)
        break
      case selectedBusinessType.startsWith(SERVICES_OPTION):
        setShowAdditionalServiceIcons(true)
        setShowCustomBusinessType(false)
        break
      default:
        setShowCustomBusinessType(false)
        setShowAdditionalServiceIcons(false)
        break
    }
  }

  useEffect(() => {
    handleAdditionalOptions(selectedBusinessType)
  }, [selectedBusinessType])

  return (
    <Container>
      <IconGrid>
        {businessTypes.map((type) => (
          <BusinessIcon
            key={type.key}
            color={
              selectedBusinessType.startsWith(type.key)
                ? "#FFFFFF"
                : "var(--sparkle-darkblue)"
            }
            onClick={() => {
              setSelectedBusinessType(type.key)
              setBusinessType(type.label)
            }}
          >
            {type.icon}
            <p>{type.label}</p>
          </BusinessIcon>
        ))}
      </IconGrid>
      {showAdditionalServiceIcons && (
        <Row
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            margin: 0,
          }}
        >
          <BusinessIcon
            key={"SERVICE-B2B"}
            flexDirection={"row"}
            justifyContent={"center"}
            color={
              selectedBusinessType === "SERVICES-B2B"
                ? "#FFFFFF"
                : "var(--sparkle-darkblue)"
            }
            onClick={() => {
              setSelectedBusinessType("SERVICES-B2B")
              setBusinessType("B2B")
            }}
          >
            <IconB2B
              style={{
                height: "auto",
                width: "clamp(1em, 2rem, 2.5rem)",
                margin: "0.5em",
              }}
            />
            <span>B2B</span>
          </BusinessIcon>
          <BusinessIcon
            key={"SERVICE-B2C"}
            flexDirection={"row"}
            justifyContent={"center"}
            color={
              selectedBusinessType === "SERVICES-B2C"
                ? "#FFFFFF"
                : "var(--sparkle-darkblue)"
            }
            onClick={() => {
              setSelectedBusinessType("SERVICES-B2C")
              setBusinessType("B2C")
            }}
          >
            <IconB2C
              style={{
                height: "auto",
                width: "clamp(1em, 2rem, 2.5rem)",
                margin: "0.5em",
              }}
            />
            <span>B2C</span>
          </BusinessIcon>
        </Row>
      )}
      {showCustomBusinessType && (
        <Row
          style={{
            margin: "0.5em 1em",
          }}
        >
          <input
            key={CUSTOM_OPTION}
            required
            type={"text"}
            name={"businessType"}
            placeholder={"Business Type *"}
            onChange={(e) => {
              const value = e.target.value
              setBusinessType(value)
            }}
            onFocus={() => {
              setSelectedBusinessType(OTHER_OPTION)
              setBusinessType("")
            }}
            value={businessType}
          />
        </Row>
      )}
    </Container>
  )
}

export { BusinessTypeOptions }

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
  gap: 0.5em;
  padding-bottom: 0.5em;
`

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: clamp(0.25em, 5px, 1em);

  margin-bottom: 0.5em;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`

const BusinessIcon = styled.div`
  padding-block: ${(props) => `${props.flexDirection === "row" ? 0 : "0.5em"}`};

  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 0.75rem;
  display: flex;
  flex-direction: ${(props) => `${props.flexDirection || "column"}`};
  justify-content: ${(props) => `${props.justifyContent || "space-around"}`};
  align-items: center;

  > p {
    font-size: clamp(0.75rem, 1.5vw, 1rem);
    font-weight: 200;
    margin-inline: auto;
    padding: 5px 15px;
    text-wrap: balance;
    text-align: center;

    min-height: 2rem;
  }
  & div {
    /* background-color: var(--sparkle-darkblue); */
    color: var(--sparkle-white);
  }
  :hover {
    // transform slowly
    transition: transform 0.25s;
    transform: translateY(-2.5px);
  }
`
