import React from "react"

const IconFood = ({ style = {}, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="240"
      viewBox="0 0 24 24"
      style={{ overflow: "visible", ...style }}
      {...props}
    >
      <path
        fill="currentColor"
        d="M1 22c0 .54.45 1 1 1h13c.56 0 1-.46 1-1v-1H1v1M8.5 9C4.75 9 1 11 1 15h15c0-4-3.75-6-7.5-6m-4.88 4c1.11-1.55 3.47-2 4.88-2s3.77.45 4.88 2H3.62M1 17h15v2H1v-2M18 5V1h-2v4h-5l.23 2h9.56l-1.4 14H18v2h1.72c.84 0 1.53-.65 1.63-1.47L23 5h-5Z"
      />
    </svg>
  )
}

export default IconFood
