import React, { createContext, useContext } from "react"
import useTenantInfo from "./useTenantInfo"
import useIsBrowser from "../hooks/useIsBrowser"
import { useAuth0 } from "../hooks/useAuth0"
import useTenantInfoContext from "./useTenantInfoContext"
import { useLocation } from "@reach/router"

export const TenantSignedInContext = createContext({})
export const TenantSignedInProvider = ({ children }) => {
  const isBrowser = useIsBrowser()
  const tenantInfo = useTenantInfoContext()
  const location = useLocation()

  // Don't show logged in data when in iframe... for now
  const inIframe = isBrowser
    ? window.location !== window.parent.location
    : false
  const { loading, user, loginWithRedirect, logout } = useAuth0()
  const [tenantSignedIn] = useTenantInfo({
    tenantId: (user || {})["https://wesparkle.biz/sparkleTenantId"],
  })

  return (
    <TenantSignedInContext.Provider
      value={
        inIframe || !isBrowser
          ? {}
          : {
              user, // might as well pass along since we already have it
              tenantSignedIn,
              loading,
              onOwnSite: tenantSignedIn?.tenantId === tenantInfo?.tenantId,
              onLogout: () => logout(),

              onLogIn: () => {
                // loginWithRedirect({
                //   appState: {
                //     targetUrl: process.env.GATSBY_SPARKLE_ADMIN_PATH,
                //   },
                // }),
                if (window) {
                  window.open(
                    `${process.env.GATSBY_SPARKLE_ADMIN_PATH}`,
                    "_self"
                  )
                }
              },
              onSignUp: () => {
                if (window) {
                  // open sign up in new tab
                  window.open(
                    `${process.env.GATSBY_SPARKLE_ADMIN_PATH}?signUp=true`,
                    "_blank"
                  )
                }
              },
              adminUrl: `${process.env.GATSBY_SPARKLE_ADMIN_PATH}dashboard/my-site`,
              // Helpers
              ...(tenantSignedIn && tenantSignedIn.tenantUrl
                ? {
                    homepageUrl: `${process.env.GATSBY_SPARKLE_PATH}${tenantSignedIn.tenantUrl}`,
                  }
                : {}),
            }
      }
    >
      {children}
    </TenantSignedInContext.Provider>
  )
}
const useTenantSignedInContext = () => {
  const context = useContext(TenantSignedInContext)
  if (!context) {
    // Let's yell at ourselves to make sure we use our Provider wrapper
    throw new Error(
      "Oooops, I'm guessing your forgot to use the Provider for this context"
    )
  }
  return context
}

export default useTenantSignedInContext
