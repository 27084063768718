// Used in gatsby-node and in tenantInfo to keep in sync
// - Order here matters, so keep in line with data in graphql query
const parseBooking = ({
  length,
  lengthType,
  description,
  descriptionLabel,
  id,
  name,
  order,
  schedulingUrl,
  hidePublic,
  unit_price: price,
  payment_account_id,
  requires_payment: rp,
  additionalFormId,
  zoom_required,
  where,
  additional_confirmation: additionalConfirmation,
  homeBaseAddress,
  calendarIds,
  isGroupClass,
  ...rest
}) => {
  // Allows to be null, true, or false (no undefined)
  const requires_payment = rp === undefined || rp === null ? null : rp || false
  // Helper prop to figure out booking payment type
  let payment_type = "free"
  if (requires_payment === null && price) payment_type = "optional"
  if (requires_payment && price) payment_type = "required"
  return {
    // !! Must be in same order to match graphql query !!
    length,
    lengthType,
    description: description || "",
    descriptionLabel: descriptionLabel || "",
    id,
    name,
    order: order || 0,
    schedulingUrl: schedulingUrl || "",
    hidePublic: hidePublic !== false,
    price: price || 0,
    where: where || (zoom_required ? "Online zoom" : ""), // Backup to online
    zoom_required: zoom_required || false,
    payment_account_id,
    requires_payment: requires_payment || false, // use payment_type to really know which type
    payment_type,
    additionalFormId,
    additionalConfirmation: additionalConfirmation || "",
    homeBaseAddress: homeBaseAddress || "",
    calendarIds: calendarIds || [],
    isGroupClass: isGroupClass || false
  }
}

module.exports = parseBooking
