import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  /* Color tokens + nums */
  :root {
    --accent:  ${({ theme }) =>
      theme.color.accent}; /* really just for logo area when signed in */
    --content-pad: 1.5rem;
    --sparkle-red: #E43C4A;
    --sparkle-darkblue: #07234C;
    --sparkle-darkblue-opacity: rgba(7, 35, 76, 0.75);
    --sparkle-darkblue-gradient: linear-gradient(225deg, #0c597d, #071e41);
    --sparkle-green: #06D6A0;
    --sparkle-green-opacity: rgba(6, 214, 160, 0.25);
    --sparkle-darkgreen: #1EA380;
    --sparkle-brightblue: #2BB8E7;
    --sparkle-brightblue-opacity: rgba(43, 184, 231, 0.25);
    --sparkle-blue: #118AB2;
    --sparkle-lightblue: #E3EFF3;
    --sparkle-lightblue-opacity: rgba(227, 239, 243, 0.25);
    --sparkle-white: #FFFFFF;
    --sparkle-yellow: #FFDD66;
    --sparkle-yellow-opacity: rgba(255, 221, 102, 0.25)
  }

  /* Remove tap highlight */
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }

  html {
    font-size: 100%;
    scroll-behavior: smooth;
  }

  html, body {
    overscroll-behavior-y: none;
  }

  body {
    color: #333;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    overscroll-behavior-x: none;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
    height: 100%;
    background: #FAF9F6;

  }


  /* body {
    background: var(--sparkle-darkblue-gradient);
    color: var(--sparkle-white);
  } */


  h1, h2, h3, h4, h5 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 1.3;
  }

  h1 {
    margin-top: 0;
    font-size:  clamp(1.75rem, 5vw, 2.5rem);
  }

  h2 {
    font-size: clamp(1.65em, 4vw, 2.074rem);
  }

  h3 {
    font-size: clamp(1.5rem, 3vw, 1.728rem);
  }

  h4 {
    font-size: clamp(1.44rem, 2.5vw, 1.44rem);
  }

  h5 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  a {
    text-decoration: none;
  }

  button {
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  input, textarea {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
  }

  /* Handy utility */
  .pos-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .pos-full {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 700px) {
    :root {
      --content-pad: 10px;
    }
  }
`

export default GlobalStyles
