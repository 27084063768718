// utils to check strings

// function to make sure string has no spaces, or special characters (except for - and _)
function isAlphaNumeric(str) {
  return /^[a-zA-Z0-9_-]*$/.test(str)
}

// function to check is email address is valid
function isValidEmail(str) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(str)
}

export { isAlphaNumeric, isValidEmail }
