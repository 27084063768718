import React from "react"

const IconSupport = (props) => {
  return (
    <svg viewBox="0 0 95.7 62.5" {...props}>
      <path d="M68.2,30.9L68.2,30.9C69.3,30,80.8,19.1,83,15.1c0,0,5.9-7.2,0-12.5c0,0-8.5-7.2-14.8,2.6C62-4.6,53.5,2.5,53.5,2.5 c-5.9,5.3,0,12.5,0,12.5C55.7,19,66.8,29.6,68.2,30.9z" />
      <path d="M88.1,38.7l-19.2,5.4v0.1c0.5,1.9,0.2,4-0.8,5.7c-1,1.9-2.7,3.2-4.8,3.8c-2,0.6-4.2,0.3-6.1-0.7l-11.1-6.2l1-1.9l11.1,6.2 c1.4,0.8,2.9,0.9,4.4,0.5c1.5-0.4,2.7-1.4,3.5-2.8c0.4-0.7,0.6-1.5,0.7-2.3c0.3-1.9-0.6-3.9-2.3-4.8l-26.9-15c0,0-2.8-1.9-6.6-0.8 c0,0,0,0-0.1,0C30.2,26,1.3,34.3,0.1,34.6H0l6.1,20.9L25.4,50c2,1.2,16.7,10.3,18.5,11.4c3.3,1.9,7.3,0.9,8.2,0.6 c0.1,0,39.4-11.2,39.4-11.2c2.7-0.8,5-4.4,3.9-8.1C94.6,39.6,91.3,37.8,88.1,38.7z" />
    </svg>
  )
}

export default IconSupport
