import { useEffect, useState } from "react"
import axios from "axios"
const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

const useSignUpCheckName = ({ text }) => {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [typing, setTyping] = useState(false)
  useEffect(() => {
    if (typing || !text) {
      // Clear out while typing or there is no text
      setError(false)
      setSuccess(false)
    } else {
      // Check if this is a valid name
      const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
      api
        .post(`CheckAndClaimSparkleSite?code=${apiCode}`, {
          requestedSiteName: text,
        })
        .then((response) => {
          if (response && response.data && response.data.isSiteNameAvailable) {
            setError(false)
            setSuccess(true)
          } else {
            setError(true)
            setSuccess(false)
          }
        })
    }
    // TODO return function to clear out a real in-flight request
  }, [typing, text])
  return [error, success, typing, setTyping]
}

export default useSignUpCheckName
