import React, { useState, useRef } from "react"
import styled from "styled-components"
import chroma from "chroma-js"
import TenantLogo from "../../core/TenantLogo"
import useTenantSignedInContext from "../../hooks/useTenantSignedInContext"
import NavBarLoginPlaceholder from "../../sparklePage/layout/NavBarLoginPlaceholder"
import AccountDropdownMenu from "./AccountDropdownMenu"

const LogoArea = styled.div`
  transition: opacity 0.2s;
  opacity: 1;
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  box-shadow: 0 0 0 2px #fff, 0 0 0px 4px ${chroma("#3790E2").alpha(0.4)}; /* TODO: this will be from the tenant at some point */
  overflow: hidden;

  &.account-desktop {
    display: block;
  }
  &.account-mobile {
    display: none;
  }

  @media screen and (max-width: 900px) {
    &.account-desktop {
      display: none;
    }
    &.account-mobile {
      display: block;
    }
  }
`
const Account = styled.div`
  .account-desktop {
    position: relative;

    .account-icon-bg,
    .pos-full {
      transition: 0.2s;
    }

    &.is-expanded {
      .account-icon-bg {
        background: var(--sparkle-red) !important;
      }
      .pos-full {
        border: 2px solid var(--sparkle-red) !important;
      }
      &:after {
        content: "";
        width: 60px;
        height: 40px;
        position: fixed;
        transform: translate(-50%);
        top: 45px;
      }
      @media screen and (min-width: 900px) {
        + .account-dropdown {
          display: block;
          position: absolute;
          background: white;
          filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
          border-radius: 15px;
          right: -12px;
          top: 45px;
          z-index: 100000;

          &:after {
            content: "";
            width: 0;
            height: 0;
            display: inline-block;
            border: 10px solid transparent;
            border-bottom-color: white;
            position: absolute;
            top: -10px;
            right: 5%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .account-mobile.is-expanded {
    .account-icon-bg {
      background: var(--sparkle-red) !important;
      transition: 0.2s;
    }
    .pos-full {
      border: 2px solid var(--sparkle-red) !important;
      transition: 0.2s;
    }
  }
`
const AccountMenu = ({
  style = {},
  trackingPrefix,
  props,
  setIsNavExpanded,
  isAccountMobileExpanded,
  setisAccountMobileExpanded,
}) => {
  const { tenantSignedIn } = useTenantSignedInContext()
  const target = useRef(null)

  const [isAccountDesktopExpanded, setIsAccountDesktopExpanded] =
    useState(false)

  const tenantInfo = tenantSignedIn || {}
  return (
    <Account>
      <LogoArea
        className={
          isAccountMobileExpanded
            ? "account-mobile is-expanded"
            : "account-mobile"
        }
        as={"button"}
        style={{
          visibility: "visible",
          cursor: "pointer",
          ...(tenantInfo?.tenantName ? {} : { boxShadow: "none" }),
          ...style,
        }}
        {...props}
        ref={target}
        onClick={() => {
          setIsNavExpanded(false)
          setisAccountMobileExpanded(!isAccountMobileExpanded)
        }}
      >
        <>
          {tenantInfo && tenantInfo.tenantName ? (
            <TenantLogo tenantInfo={tenantInfo} />
          ) : (
            <NavBarLoginPlaceholder />
          )}
        </>
      </LogoArea>
      <LogoArea
        className={
          isAccountDesktopExpanded
            ? "account-desktop is-expanded"
            : "account-desktop"
        }
        as={"button"}
        style={{
          visibility: "visible",
          cursor: "pointer",
          ...(tenantInfo?.tenantName ? {} : { boxShadow: "none" }),
          ...style,
        }}
        {...props}
        ref={target}
        onMouseEnter={() => {
          setIsAccountDesktopExpanded(true)
        }}
        onMouseLeave={() => {
          setIsAccountDesktopExpanded(false)
        }}
      >
        <>
          {tenantInfo && tenantInfo.tenantName ? (
            <TenantLogo tenantInfo={tenantInfo} />
          ) : (
            <NavBarLoginPlaceholder />
          )}
        </>
        <div className="hover-helper" />
      </LogoArea>
      <AccountDropdownMenu
        isAccountDesktopExpanded={isAccountDesktopExpanded}
        setIsAccountDesktopExpanded={setIsAccountDesktopExpanded}
        trackingPrefix={trackingPrefix}
        tenantInfo={tenantInfo}
        isAccountMobileExpanded={isAccountMobileExpanded}
      />
    </Account>
  )
}

export default AccountMenu
