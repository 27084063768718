// These are the ZOHO product codes (monthly versions), DO NOT CHANGE
// For annual plans, the convention is appending '_ANNUAL' at the end
// OBVS, free plans are neither monthly or annual :)
const LEGACY_FREE_PLAN = "FREE"
const FREE_PLAN = "FREE_PLAN"
const STARTER_PLAN = "STARTER"
const PRO_PLAN = "BASIC"
const PREMIUM_PLAN = "BUSINESS"
const ANNUAL_PLAN_SUFFIX = "-ANNUAL"

// These are product feature keys
const LINKS_FEATURE = "LINKS"
const PRODUCTS_SERVICES_FEATURE = "PRODUCTS_SERVICES"
const PRODUCTS_FEATURE = "PRODUCTS"
const SERVICES_FEATURE = "SERVICES"
const APPOINTMENTS_FEATURE = "APPOINTMENTS"
const GROUP_EVENTS_FEATURE = "GROUP_EVENTS"
const FAQS_FEATURE = "FAQS"
const CONTACTS_FEATURE = "CONTACTS"
// const SMS_FEATURE = "SMS"
const TRANSACTION_FEE_FEATURE = "TRANSACTION_FEE"
const WELLNESS_FEATURE = "WELLNESS"

// These are the options for busisness type
const ARTS_OPTION = "ARTS"
const FOODDRINK_OPTION = "FOODDRINK"
const HEALTH_OPTION = "HEALTH"
const RETAIL_OPTION = "RETAIL"
const SERVICES_OPTION = "SERVICES"
const OTHER_OPTION = "OTHER"
const CUSTOM_OPTION = "OTHER-CUSTOM"

export {
  ANNUAL_PLAN_SUFFIX,
  APPOINTMENTS_FEATURE,
  CONTACTS_FEATURE,
  FAQS_FEATURE,
  FREE_PLAN,
  GROUP_EVENTS_FEATURE,
  LEGACY_FREE_PLAN,
  LINKS_FEATURE,
  PREMIUM_PLAN,
  PRO_PLAN,
  PRODUCTS_FEATURE,
  PRODUCTS_SERVICES_FEATURE,
  SERVICES_FEATURE,
  // SMS_FEATURE,
  STARTER_PLAN,
  TRANSACTION_FEE_FEATURE,
  WELLNESS_FEATURE,
  ARTS_OPTION,
  FOODDRINK_OPTION,
  HEALTH_OPTION,
  RETAIL_OPTION,
  SERVICES_OPTION,
  OTHER_OPTION,
  CUSTOM_OPTION,
}
