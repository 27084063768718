import React, { useState, useRef, useEffect } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"

import { segmentAction } from "../../hooks/useSegmentTrack"
import { ButtonSecondary, ButtonPrimary } from "../../homepage/Buttons"
import Logo from "../../icons/IconLogo"
import Account from "./Account"
import { StickyHeader } from "../../core/StickyHeader"

import useTenantSignedInContext from "../../hooks/useTenantSignedInContext"
import { useStickyHeaderContext } from "../../context/pricing"
import { set } from "lodash"

const NavBar = ({ links = [] }) => {
  const { tenantSignedIn = {}, loading, onLogIn } = useTenantSignedInContext()
  const tenantIsSignedIn = tenantSignedIn?.tenantName && !loading
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [isAccountMobileExpanded, setisAccountMobileExpanded] = useState(false)
  const { top } = useStickyHeaderContext()

  return (
    <StickyHeader
      style={{
        backgroundColor: "#fff",
        width: "100%",
      }}
      zIndex={2}
      isSticky={true}
    >
      <Nav id="top" top={top}>
        <Link
          to={"/"}
          style={{ lineHeight: 0 }}
          onClick={() =>
            segmentAction.track(`Homepage Nav Clicked`, { value: "logo" })
          }
        >
          <Logo markProps={{ fill: "var(--sparkle-red)" }} alt={"We Sparkle"} />
        </Link>
        <div
          className={
            isNavExpanded ? "navigation-menu is-expanded" : "navigation-menu"
          }
        >
          <ul>
            {!tenantIsSignedIn && (
              <>
                <li className="nav-item login-mobile">
                  <button
                    style={{
                      border: "2px solid black",
                      borderRadius: 100,
                      padding: "0.15rem ",
                      width: "100%",
                      marginBottom: "0.5rem",
                    }}
                    onClick={() => {
                      segmentAction.track("Homepage Login Click")
                      onLogIn()
                    }}
                  >
                    Login →
                  </button>

                  <Link
                    to={"/signup"}
                    className="signup-button"
                    style={{
                      backgroundColor: "var(--sparkle-red)",
                      color: "#fff",
                      fontWeight: "bold",
                      borderRadius: 100,
                      padding: " 1rem",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      segmentAction.track("Navbar SignUp button Click")
                      setIsNavExpanded(!isNavExpanded)
                    }}
                  >
                    Sign Up
                  </Link>
                </li>
              </>
            )}
            {links.map(({ name, href, ...rest }, i) => (
              <li className="nav-item" key={i}>
                <Link
                  as={"a"}
                  to={href}
                  onClick={() => {
                    setIsNavExpanded(false)
                    segmentAction.track(`Homepage Nav Clicked`, {
                      value: name,
                    })
                  }}
                  {...rest}
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={isNavExpanded ? "cta is-expanded" : "cta"}>
          <ButtonSecondary
            className="login-desktop"
            onClick={() => {
              segmentAction.track("Homepage Login Click")
              onLogIn()
            }}
            style={{
              ...(tenantIsSignedIn
                ? {
                    visibility: "hidden",
                  }
                : {}),
            }}
          >
            Login
          </ButtonSecondary>
          {/* Signup btn */}

          <Link
            to={"/signup"}
            className="signup-button"
            style={{
              backgroundColor: "var(--sparkle-red)",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: 100,
              padding: "0.5rem 1rem",

              ...(tenantIsSignedIn
                ? {
                    visibility: "hidden",
                  }
                : {}),
            }}
            onClick={() => {
              segmentAction.track("Navbar SignUp button Click")
              setIsNavExpanded(false)
            }}
          >
            Sign Up
          </Link>

          {/* User logged in icon */}
          {!loading && tenantIsSignedIn && (
            <Account
              isNavExpanded={isNavExpanded}
              isAccountMobileExpanded={isAccountMobileExpanded}
              setisAccountMobileExpanded={setisAccountMobileExpanded}
              setIsNavExpanded={setIsNavExpanded}
              trackingPrefix={"Homepage"}
            />
          )}
          <button
            className={isNavExpanded ? "hamburger is-expanded" : "hamburger"}
            onClick={() => {
              setisAccountMobileExpanded(false)
              setIsNavExpanded(!isNavExpanded)
            }}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </Nav>
    </StickyHeader>
  )
}

export default NavBar

const Nav = styled.nav`
  --navbar-height: ${(props) => `${props.top - 10}px` || "40px"};
  display: flex;
  position: sticky;
  top: 0;
  margin: 0 auto;

  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;

  max-width: 70rem;

  background: #fff;
  color: #222;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  z-index: 1000;
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 1px;
  }

  a {
    margin-bottom: 0;
  }
  svg {
    height: 27px;
    width: auto;
  }
  .signup-button:hover {
    background: #fb4c5b;
  }

  .navigation-menu {
    ul {
      display: flex;
      align-items: center;
      margin-top: 0.2em;

      a {
        line-height: 0;
        position: relative;
        color: inherit;
        &:after {
          transition: 0.125s;
          content: "";
          width: 100%;
          height: 4px;
          background: var(--sparkle-red);
          position: absolute;
          left: 0px;
          bottom: -8px;
          transform-origin: 0 center;
          transform: scaleX(0);
        }
      }
      a:hover:after {
        transform: scaleX(100%);
      }

      li {
        list-style: none;
        margin-left: 1.5rem;

        &.nav-item:first-of-type {
          margin-left: 0;
        }
      }
    }
    .signup-mobile,
    .login-mobile {
      display: none;
    }
  }
  .cta {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
  }
  .hamburger {
    width: 35px;
    height: 25px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: none;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: var(--sparkle-darkblue);
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0px;
    }
    span:nth-child(2),
    span:nth-child(3) {
      top: 10px;
    }
    span:nth-child(4) {
      top: 20px;
    }
  }

  @media screen and (max-width: 900px) {
    position: sticky;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px;

    .login-desktop,
    .signup-button {
      display: none;
    }
    svg {
      height: 28px;
      width: auto;
    }
    .cta {
      position: unset;
      &.is-expanded {
        .signup-desktop {
          display: none;
        }
      }
    }
    .navigation-menu {
      display: none;
      position: absolute;
      top: var(--navbar-height);
      left: 0;
      width: 100%;
      height: calc(100vh - 52px);
      background-color: white;
      z-index: 1000;

      ul {
        flex-direction: column;
        margin: 1rem;
        padding: 0 var(--content-pad);

        li {
          width: 100%;
          text-align: left;
          margin: 0;
          padding: 2rem 0;
          border-bottom: 1px solid #ced3db;

          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border: none;
          }
          a,
          button {
            letter-spacing: 0;
            text-transform: none;
            font-size: 1.25rem;
            font-weight: bold;
          }
        }
      }
      &.is-expanded {
        display: block;
        .signup-mobile,
        .login-mobile,
        .signup-button {
          display: block;
        }
      }
    }
    .hamburger {
      display: block;
      margin: 0.5rem 0;

      &.is-expanded {
        display: block;
        span:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        span:nth-child(2) {
          transform: rotate(45deg);
        }
        span:nth-child(3) {
          transform: rotate(-45deg);
        }
        span:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }

    ${ButtonSecondary}, ${ButtonPrimary} {
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: 375px) {
    .cta {
      .signup-desktop,
      .signup-button {
        display: none;
      }
    }
  }
`
