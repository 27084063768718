import moment from "moment"
import "moment-timezone"
import axios from "axios"
import { navigate } from "gatsby"

const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

// formatter.format(2500); /* $2,500.00 *
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

export const getEmptyProductPatternId = (tenantInfo) =>
  "emptyProductPatternId" + (tenantInfo?.tenantId || "homepage") // Also used in shopping cart

// Takes a value like 14212 => 142.12
export const parseCost = (cost) => {
  const dollars = (cost + "").slice(0, -2)
  const cents = (cost + "").slice(-2)
  return `${dollars}.${cents}`
}

export const parseTimeForBookingsSummary = ({ start, end }) => {
  const mStart = moment(start)
  const mEnd = moment(end)
  const date = mStart.format("ddd, MMM D, YYYY")
  // Using "thin space" between https://www.compart.com/en/unicode/U+2009
  const time = `${mStart.format("h:mm a")} – ${mEnd.format("h:mm a")} `

  // Finessing moment "from now" feature
  // - ie if booking is tomorrow don't say 22 hours from now and send someone into a panic
  // - ie if booking is 20 days from now say how many weeks instead
  const tomorrow = moment().add("day").endOf("day")
  const hours = Math.round(moment.duration(start - tomorrow).asHours())
  const momentFromNow = mStart.fromNow(true)
  const daysTotal = momentFromNow.split(" days")[0]
  let fromNow =
    hours > 6 && hours < 23 ? "A day from now" : momentFromNow + " from now" //don't say 22 hours and cause someone to panic say tomorrow instead
  if (!isNaN(daysTotal)) {
    const weeks = Math.floor(daysTotal / 7)
    const days = daysTotal % 7
    fromNow = `${
      weeks > 0
        ? `${weeks === 1 ? "A" : weeks} week${weeks > 1 ? "s" : ""}`
        : ""
    }${weeks && days ? ` and ` : ""}${
      days > 0 ? `${days === 1 ? "a" : days} day${days > 1 ? "s" : ""}` : ""
    } from now`
  }
  return {
    time,
    date,
    fromNow,
  }
}

// Time
const defaultLength = "minutes"
const getTime = ({ length, lengthType }) => {
  switch (lengthType || defaultLength) {
    case "minutes": {
      const hours = Math.floor(length / 60)
      const minutes = length - hours * 60
      return `${hours ? hours + ` hour${hours > 1 ? "s" : ""} ` : ""}${
        minutes ? minutes + " minutes" : ""
      }`
    }
    case "hours": {
      const days = Math.floor(length / 24)
      const hours = length - days * 24
      return `${days ? days + ` day${days > 1 ? "s" : ""} ` : ""}${
        hours ? hours + ` hour${hours > 1 ? "s" : ""}` : ""
      }`
    }
    default:
      console.error("This length type has not been accounted for.")
      return `${length} ${lengthType}`
  }
}

// For cancel or confirming a booking
export const parseBookingForSuccessAndCancel = ({
  bookingInfo,
  selectedBooking,
  selectedBooking: { name } = {},
  applyTimezone, // Needed on return modals after exiting site NOT while making bookings (ie Cancel from email + Success after payment)
}) => {
  const {
    start,
    what,
    where: bookingInfoWhere,
    end,
    timeZone,
  } = bookingInfo || {}
  const where = bookingInfoWhere || selectedBooking.where
  let mStart = moment(start)
  let mEnd = moment(end)
  if (applyTimezone && timeZone) {
    mStart = mStart.tz(timeZone)
    mEnd = mEnd.tz(timeZone)
  }
  const text = []
  if (what)
    text.push(
      what.toLowerCase().match(/\d+\s(minute||hour)s?/g)
        ? what + " Booking"
        : what
    )
  if (name && !what)
    text.push(
      name.toLowerCase().match(/\d+\s(minute||hour)s?$/g)
        ? name + " Booking"
        : name
    )
  if (start && end) {
    const timeRange = `${mStart.format("h:mm a")} – ${mEnd.format("h:mm a")}`
    text.push(mStart.format("ddd MMM D, YYYY"))
    text.push(timeRange)
  }
  if (timeZone)
    text.push("Timezone " + timeZone.replace(/_/g, " ").replace(/\//g, "/")) // remove _ , could use timezone moment obj too for abbr etc.
  if (where) {
    text.push(where.includes("zoom.us") ? "Zoom meeting" : where)
  }

  return text
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const parseBookingText = ({ length, lengthType, name = "" }) => {
  const time = getTime({ length, lengthType })
  const words = name.split(" ")
  const nameIsTime = time.toLowerCase().trim() === name.toLowerCase().trim()
  if (nameIsTime) words.push("Booking")
  return {
    name: words.join(" "),
    time,
  }
}

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "")
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return null
}

export const getSparkleNumber = ({
  tenantInfo: { tenantChatClients, tenantSparkleNumberHide } = {},
}) => {
  if (tenantChatClients) {
    const { tenant_chat_client_phoneNumber: number } =
      tenantChatClients.find(
        ({ tenant_chat_client_phoneNumber }) => tenant_chat_client_phoneNumber
      ) || {}
    if (number && !tenantSparkleNumberHide) {
      return formatPhoneNumber(number)
    }
  }
}

export const showOnlyPublic = ({ hidePublic }) => !hidePublic

export const getShowBookings = ({
  tenantInfo: {
    tenantAppointmentTypes: individualBookings,
    tenantGroupClasses: groupBookings,
  },
}) => {
  const availIndividualBookings =
    (individualBookings || []).filter(showOnlyPublic) || []
  const availGroupBookings = (groupBookings || []).filter(showOnlyPublic) || []
  return availIndividualBookings.length > 0 || availGroupBookings.length > 0
}

// TODO: Move into useEffect or... move into Bookings Contact comp?
export const onContactSubmit = ({
  bookingInfo: {
    resources: bookingInfoResources,
    start,
    end,
    booking: { graph, id: bookingId, resource: bookingResource } = {},
  } = {},
  setView,
  ignorePayment,
  setIsProcessing,
  timeZone,
  tenantId,
  setPendingPaymentId,
  bookingCompleted,
  customerInfo,
  selectedBooking: { id: projectId, requires_payment, payment_type } = {},
}) => {
  setIsProcessing(true)
  const isGroupBooking = graph === "group_owner"
  const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
  const resources = (bookingInfoResources || [bookingResource]).map(
    ({ id, name, timezone }) => ({
      id,
      name,
      timezone,
    })
  )
  const resourceId = (resources[0] || {}).id
  api
    .post(`CreateBooking?code=${apiCode}`, {
      projectId,
      booking: {
        resources,
        start: start.format(),
        end: end.format(),
        tzOffsetMinutes: timeZone,
        isGroupBooking,
        groupClassBookingId: isGroupBooking ? bookingId : null,
      },
      customerInfo,
      resourceId,
      pendingPayment: requires_payment,
      tenantId,
      ...(payment_type === "optional" ? { ignorePayment } : {}),
    })
    .then((response) => {
      // Pening payment booking ids are only for pay routes (including optional + pay)
      const { pendingPaymentBookingId: id } = response.data
      if (id) {
        // Payment route
        setPendingPaymentId(id)
      } else {
        // No payment route
        setView("success")
        bookingCompleted()
      }
    })
    .catch((error) => {
      // log error
      // console.log(error)
      setView("error")
    })
    .finally(() => {
      setIsProcessing(false)
    })
}

export const findBookingById = ({
  id: currentId,
  individualBookings,
  groupBookings,
}) => {
  if (currentId) {
    const individual = individualBookings.find(({ id }) => id === currentId)
    if (individual) return { ...individual, type: "Individual" }
    // Then group
    const group = groupBookings.find(({ id }) => id === currentId)
    if (group) return { ...group, type: "Group" }
  }
}

export const clearHashAndQueries = () => {
  const url = window.location.href || document.URL // doc url is supposedly for FF (haven't test that part out yet)
  // Not a super "smart" regex finds a ? or a # and clears EVERYTHING after it
  return url.replace(/[?|#].*/g, "")
}

export const clearBooking = () => {
  navigate(clearHashAndQueries() + "#bookings") // keep in the booking section
}

export const getSelectedBooking = ({
  tenantInfo: {
    tenantAppointmentTypes: individualBookings = [],
    tenantGroupClasses: groupBookings = [],
  },
  bookingId,
}) => {
  const selectedBooking =
    findBookingById({
      id: bookingId,
      individualBookings,
      groupBookings,
    }) || null
  return selectedBooking
}
