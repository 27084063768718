/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import ReactGA from "react-ga4"
import { Location } from "@reach/router"
import { ThemeProvider } from "styled-components"

import { Auth0Provider } from "./src/hooks/useAuth0"
import {
  StickyHeaderProvider,
  PricingPlansProvider,
} from "./src/context/pricing"
import { TenantSignedInProvider } from "./src/hooks/useTenantSignedInContext"
import { TenantInfoProvider } from "./src/hooks/useTenantInfoContext"

import { SignUpProvider } from "./src/hooks/useSignUpContext"
import { ShowHideModalProvider } from "./src/hooks/useShowHideModalContext"

import Layout from "./src/components/layout"

export const wrapRootElement = ({ element }) => {
  if (
    (window && (process.env.GATSBY_SPARKLE_PATH === "https://sprkl.es/" ||
      process.env.GATSBY_SPARKLE_PATH === "https://sparkl.es" ||
      process.env.GATSBY_SPARKLE_PATH === "https://wesparkle.co" ||
      process.env.GATSBY_SPARKLE_PATH === "https://www.wesparkle.co"))
  ) {
    /* 
      https://github.com/codler/react-ga4#readme
      https://github.com/react-ga/react-ga/issues/296#issuecomment-680128111 
      https://support.google.com/analytics/answer/1034342#zippy=%2Cin-this-article
    */
    ReactGA.initialize([
      {
        trackingId: "G-PT5FY2RX6K",
        gaOptions: {
          allowLinker: true
        },
      }
    ])

    ReactGA.ga('require', 'linker');
    ReactGA.ga('linker:autoLink', ['wesparkle.co', 'sprkl.es', 'sparkl.es', 'subscriptions.wesparkle.ai']);
  }
  return (
    <Location>
      {({ location }) => (
        <Auth0Provider>
          <TenantInfoProvider>
            <TenantSignedInProvider>
              <ShowHideModalProvider>
                <PricingPlansProvider>
                  <StickyHeaderProvider>
                    <SignUpProvider>{element}</SignUpProvider>
                  </StickyHeaderProvider>
                </PricingPlansProvider>
              </ShowHideModalProvider>
            </TenantSignedInProvider>
          </TenantInfoProvider>
        </Auth0Provider>
      )}
    </Location>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={{ color: { accent: "#3790E2" } }}>
      <Layout {...props}>{element}</Layout>
    </ThemeProvider>
  )
}

// Logs when the client route changes
export const onRouteUpdate = ({ location }) => {
  // console.log("new pathname", location.pathname)
  // console.log("old pathname", prevLocation ? prevLocation.pathname : null)

  if (
    process.env.GATSBY_SPARKLE_PATH === "https://sprkl.es/" ||
    process.env.GATSBY_SPARKLE_PATH === "https://sparkl.es"
  ) {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: `${location.pathname + location.search}`,
    })
  }
}
