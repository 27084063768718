import React from "react"
import styled from "styled-components"

const Subtext = styled.p`
  color: ${(props) => props.color};
  font-size: clamp(0.65rem, 0.6115rem + 0.2051vw, 0.75rem);
  font-weight: 300;
  /* max-width: 15ch; */
  text-wrap: balance;
  vertical-align: middle;
  margin: auto;
  @media screen and (max-width: 40em) {
    /* max-width: 18ch; */
  }
`

const Cost = styled.div`
  /* margin-block: 0.5em; */
`
const Dollar = styled.span`
  line-height: 1;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : "clamp(2rem, 1.5rem + 1vw, 3rem)"};
  font-weight: bold;

  sup {
    padding-inline: 0.15rem;
    font-size: 0.75rem;
    vertical-align: top;
  }
  sub {
    font-size: 0.75rem;
    vertical-align: middle;
  }
`

export { Cost, Dollar, Subtext }
