import React from "react"

// Pre login
const NavBarLoginPlaceholder = () => {
  return (
    <div
      className="account-icon-bg"
      style={{
        width: "100%",
        height: "100%",
        background: "#555",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          borderRadius: "100%",
          background: "#fff",
          width: "30%",
          height: "30%",
          left: "35%",
          top: "25%",
        }}
      />
      <div
        style={{
          position: "absolute",
          borderRadius: "100%",
          background: "#fff",
          width: "100%",
          height: "150%",
          left: "0%",
          top: "65%",
        }}
      />
      <div
        className={"pos-full"}
        style={{
          width: "100%",
          borderRadius: "100%",
          height: "100%",
          border: "2px solid #555",
        }}
      />
    </div>
  )
}

export default NavBarLoginPlaceholder
