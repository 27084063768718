import React, { useState, useRef, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { navigate } from "gatsby"

import { useAuth0 } from "../../hooks/useAuth0"
import usePrevious from "../../hooks/usePrevious"
import { segmentAction } from "../../hooks/useSegmentTrack"
import { useShowHideModalContext } from "../../hooks/useShowHideModalContext"
import useSignUpCheckName from "../../hooks/useSignUpCheckName"
import useSignUpContext from "../../hooks/useSignUpContext"
import { onSignUpClickStart } from "../../hooks/useSignUpClickStart"

import { ButtonPrimary } from "../../homepage/Buttons"
import SignUpIndicator from "../../homepage/SignUpIndicator"
import IconLoaderCircle from "../../icons/IconLoaderCircle"

import { BusinessTypeOptions } from "./BusinessTypeOptions"

import { isAlphaNumeric, isValidEmail } from "../../utils/stringValidation"

const SignUp = ({
  btnText,
  plan,
  monthly,
  showLeadCapture = false,
  buttonAction = "start",
  // autoFocus, // Pass in autofocus for just the first sign up... adds cursor
}) => {
  const {
    text,
    setText,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    businessType,
    setBusinessType,
  } = useSignUpContext()
  const { user } = useAuth0()
  const [error, success, typing, setTyping] = useSignUpCheckName({ text })

  const [loadingPurchaseUrl, setLoadingPurchaseUrl] = useState(false)
  const [isFocused, setFocus] = useState()
  const prevFocus = usePrevious(isFocused)

  const [errorType, setErrorType] = useState(null)

  const timeoutRef = useRef(null)

  // Is a request fetching
  const fetching = !typing && text && !error && !success

  // getErrorMessages to return appropriate error messages
  const getErrorMessage = (errorType) => {
    switch (errorType) {
      case "emptyName":
        return "Please enter a site name."
      case "invalidName":
        return "No special characters or spaces allowed."
      case "sparkleSiteExists":
        return "Name already taken, please try again."
      case "emptyEmail":
        return "Email address is required."
      case "invalidEmail":
        return "Please enter a valid email address."
      default:
        return "Name already taken, please try again."
    }
  }

  function handleSubmit() {
    // first validate email
    const emailValid = isValidEmail(email)
    const validSparkleName = isAlphaNumeric(text)

    if (text && !validSparkleName) {
      setErrorType("invalidName")
    } else if (error) {
      setErrorType("sparkleSiteExists")
    } else if (!text) {
      setErrorType("emptyName")
    } else if (!emailValid && email) {
      setErrorType("invalidEmail")
    } else if (!emailValid && !email) {
      setErrorType("emptyEmail")
    } else if (validSparkleName && !error && emailValid) {
      setErrorType(null)
      // If we have a valid name, then we can go ahead and submit
      onSignUpClickStart({
        text,
        success,
        plan,
        monthly,
        setLoadingPurchaseUrl,
        user,
        email,
        firstName,
        lastName,
        businessType,
      })
    }
  }

  useEffect(() => {
    // if errorType is "sparkleSiteExists" and no more error and  yes success, clear errorType
    if (errorType === "sparkleSiteExists" && !error && success) {
      setErrorType(null)
    }
  }, [error, success])

  return (
    <>
      <div
        id="validationMsg"
        style={{
          zIndex: 0,
          textAlign: "left",
          fontSize: "0.75rem",
        }}
      >
        &nbsp;
        {(errorType || error) && (
          <span style={{ color: "var(--sparkle-red)" }}>
            {getErrorMessage(errorType)}
          </span>
        )}
        {success && "\u00a0"}
      </div>
      <Container>
        <label
          htmlFor="SparkleSiteName"
          style={{ position: "relative", flex: 1 }}
        >
          <div className={"text"}>sparkl.es/</div>
          <input
            className={"sparkle-site"}
            id={"SparkleSiteName"}
            type={"text"}
            placeholder={"youraccountname"}
            value={text}
            onChange={(e) => {
              if (isFocused && !prevFocus) {
                // Only want to track when first starting to type until focused again
                segmentAction.track("Homepage Url Type")
              }
              const val = e.target.value
              setText(val)

              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
              }
              if (!typing) setTyping(true)
              timeoutRef.current = setTimeout(() => {
                clearTimeout(timeoutRef.current)
                setTyping(false)
              }, 500)
            }}
          />

          <span
            className={"notification"}
            onClick={() => {
              if (error) {
                setText("") // clear out if we click on the "x" on error state
                setErrorType(null)
              }
            }}
          >
            <SignUpIndicator
              error={error}
              success={success}
              loading={fetching}
            />
          </span>
        </label>
      </Container>

      {showLeadCapture && (
        <Form>
          <label
            style={{
              position: "relative",
              display: "block",
            }}
            className="email"
          >
            <input
              type={"email"}
              name={"email"}
              placeholder={"Email *"}
              required
              onChange={(e) => {
                const value = e.target.value
                setEmail(value)

                setErrorType(null)
              }}
              value={email}
            />
          </label>
          <label
            style={{
              position: "relative",
              display: "inline-block",
            }}
            className="first-name"
          >
            <input
              type={"firstName"}
              name={"firstName"}
              placeholder={"First Name"}
              onChange={(e) => {
                const value = e.target.value
                setFirstName(value)
              }}
              value={firstName}
            />
          </label>
          <label
            style={{
              position: "relative",
              display: "inline-block",
            }}
            className="last-name"
          >
            <input
              type={"lastName"}
              name={"lastName"}
              placeholder={"Last Name"}
              onChange={(e) => {
                const value = e.target.value
                setLastName(value)
              }}
              value={lastName}
            />
          </label>

          <label
            style={{
              position: "relative",
              display: "block",
            }}
          >
            <BusinessTypeOptions />
          </label>
        </Form>
      )}

      <Button
        onClick={() => {
          if (buttonAction === "start") {
            // Open modal
            segmentAction.track("Navigated to Signup")
            // setShow(true)
            navigate("/signup")
          } else {
            // Continue to zoho registration/signup process
            segmentAction.track("Signup Continue Button Clicked")
            // console.log("onSignUpClickStart, continue")

            // onSignUpClickStart({
            //   text,
            //   success,
            //   plan,
            //   monthly,
            //   setLoadingPurchaseUrl,
            //   user,
            //   email,
            //   firstName,
            //   lastName,
            //   businessType,
            // })
            handleSubmit()
          }
        }}
        disabled={loadingPurchaseUrl || errorType}
        style={{marginBottom: "1rem"}}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginRight: "0.5rem",
              }}
            >
              {btnText || "Start now "}
            </div>

            {loadingPurchaseUrl ? (
              <IconLoaderCircle
                indicator={"#fff"}
                widthPercent={0.17}
                bg={"rgba(255,255,255,.5)"}
                length={20}
              />
            ) : (
              <ButtonArrowMovement> →</ButtonArrowMovement>
            )}
          </div>
        </>
      </Button>
    </>
  )
}

export default SignUp

const shift = keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(10px);
  }
`

/***********************************************
 *
 *    STYLED COMPONENTS
 *
 * **********************************************
 */

const Container = styled.div`
  color: #222222;
  font-size: 1.2rem;
  display: flex;
  > label {
    display: flex;
    position: relative;
  }
  .notification {
    height: 100%;
    position: absolute;
    display: flex;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    span {
      margin: auto;
    }
  }
  .text {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    pointer-events: none;
    height: 100%;
    position: absolute;
    font-weight: 700;
    margin: auto;

    z-index: 1;
  }
  input {
    width: 100%;
    padding-block: 0.65em;
    padding-left: 11ch;
    border-radius: 100px;

    background: #efefef;
    font-size: 1.2rem;
    font-weight: 300;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.2rem;
    display: block;
    .text {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      pointer-events: none;
      height: 100%;
      position: absolute;
      font-weight: bold;
      margin: auto;

      z-index: 1;
    }
    input {
      margin-left: 0;
      margin-block: auto;
      width: 100%;
      padding-block: 0.5em;
    }
    .notification {
      right: 0.5rem;
    }
  }
`

const ButtonArrowMovement = styled.div`
  font-family: "Tahoma";
  padding-block: 0.5em;
  /* @media screen and (max-width: 900px) {
    margin-left: 0px;
  } */
`
const Button = styled(ButtonPrimary)`
  font-size: 1.2rem;
  width: 100%;
  justify-content: center;
  margin-top: 0.25rem;

  :hover ${ButtonArrowMovement} {
    animation: none;
  }

  &[disabled] {
    background-color: #ccc;
    pointer-events: none;
  }
`

const Form = styled.form`
  margin-top: 0.5em;
  input {
    width: 100%;
    padding: 0.65em 0.75em;
    padding-left: 1em;
    border-radius: 100px;
    font-size: 1.1rem;
    display: block;
    margin-bottom: 0.5em;
    ::placeholder {
      color: #999;
      font-weight: 300;
    }
    ::-webkit-input-placeholder {
      /* Edge */
      color: #999;
      font-weight: 300;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #999;
      font-weight: 300;
    }
  }
  .first-name {
    width: 48%;
    margin-right: 4%;
  }
  .last-name {
    width: 48%;
  }
  @media screen and (max-width: 900px) {
    .first-name,
    .last-name,
    .email,
    .sparkle-site {
      width: 100%;
      margin: 0.25rem 0 0;
    }
  }
`

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: clamp(0.25rem, 0.5rem, 1rem);
  justify-self: space-around;
`

const BusinessIcon = styled.div`
  aspect-ratio: 1/1;
  padding: 0.5rem;
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;

  > p {
    font-size: clamp(0.5rem, 0.65rem + 0.2vw, 0.75rem);
    margin: auto;
    font-weight: 300;
    text-wrap: balance;
    text-align: center;
  }
  & div {
    /* background-color: var(--sparkle-darkblue); */
    color: var(--sparkle-white);
  }
  :hover {
    // transform slowly
    transition: transform 0.25s;
    transform: translateY(-2.5px);
  }
`
