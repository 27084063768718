import React from "react"

const IconHeart = (props) => {
  return (
    <svg viewBox="0 0 66 63" {...props}>
      <path
        transform="translate(-17, -18)"
        d="M49.9648727,30.1385 C54.0071034,22.3795 58.1014079,18.5 66.1826147,18.5 C75.1262943,18.5 82.4036114,25.4518557 82.4036114,34.018 C82.4036114,49.536 66.1826147,65.054 49.9648727,80.572 C33.7503853,65.054 17.5293886,49.536 17.5293886,34.018 C17.5293886,25.4518557 24.8067057,18.5 33.7503853,18.5 C41.8869205,18.5 45.9258966,22.3795 49.9648727,30.1385"
      ></path>
    </svg>
  )
}

export default IconHeart
