import styled from "styled-components"

// TODO: Focus / focus-visible styling
export const ButtonBase = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  line-height: 1;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  display: inline-block; /* If we display btn as a link <a> this is helpful */
  text-decoration: none; /* If we display btn as a link <a> this is helpful */
  :disabled {
    /* cursor: not-allowed; */
    pointer-events: none;
  }
  @media (hover: hover) {
    :hover {
      text-decoration: none; /* If we display btn as a link <a> this is helpful */
    }
  }
`
export const ButtonPrimary = styled(ButtonBase)`
  background: var(--sparkle-red);
  color: #fff;
  transition: 0.2s;
  font-weight: bold;
  border-radius: 1000px;
  letter-spacing: 0.035em;
  white-space: nowrap;
  line-height: 0;
  padding: 1em;

  :hover {
    background: #fb4c5b;
  }
`
export const ButtonSecondary = styled(ButtonPrimary)`
  background: #fff;
  color: #222;
  :hover {
    background: #e3eff3;
  }
`
