import React, { createContext, useContext, useState, useEffect } from "react"
import usePricingPlans from "../hooks/usePricingPlans"

const StickyHeaderContext = createContext()
const PricingPlansContext = createContext()

const StickyHeaderProvider = ({ children }) => {
  const [top, setTop] = useState(54)
  const [activeHeader, setActiveHeader] = useState("pricing-header")

  useEffect(() => {
    function updateTop() {
      // get the height of the nav
      const navHeight = document.getElementById("top").offsetHeight
      setTop(navHeight)
    }

    window.addEventListener("resize", updateTop)

    return () => {
      window.removeEventListener("resize", updateTop)
    }
  }, [top])

  const handleHeaderChange = (header) => {
    setActiveHeader(header)
  }

  const headerContext = {
    top,
    setTop,
    activeHeader,
    handleHeaderChange,
  }

  return (
    <StickyHeaderContext.Provider value={headerContext}>
      {children}
    </StickyHeaderContext.Provider>
  )
}

const PricingPlansProvider = ({ children }) => {
  const pricingValues = usePricingPlans()

  return (
    <PricingPlansContext.Provider value={pricingValues}>
      {children}
    </PricingPlansContext.Provider>
  )
}

const usePricingPlansContext = () => {
  const context = useContext(PricingPlansContext)
  if (context === undefined) {
    throw new Error(
      `usePricingPlansContext must be used within a PricingPlansProvider`
    )
  }
  return context
}

const useStickyHeaderContext = () => {
  const context = useContext(StickyHeaderContext)
  if (context === undefined) {
    throw new Error(
      `useStickyHeaderContext must be used within a StickyHeaderProvider`
    )
  }
  return context
}

export {
  StickyHeaderProvider,
  PricingPlansProvider,
  useStickyHeaderContext,
  usePricingPlansContext,
}
