import React from "react"

const IconB2B = ({ style = {}, ...props }) => {
  return (
    <svg
      width="308"
      height="308"
      viewBox="0 0 308 308"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ overflow: "visible", ...style }}
      {...props}
    >
      <mask
        id="mask0_8_239"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="210"
        width="114"
        height="98"
      >
        <path
          d="M0.0258789 210.333H113.333V307.396H0.0258789V210.333Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_8_239)">
        <path
          d="M80.5154 237.38C79.057 235.917 76.8643 235.484 74.9477 236.271C73.0362 237.062 71.7966 238.927 71.7966 240.989V250.687C60.156 248.318 51.3643 237.995 51.3643 225.667V215.448C51.3643 212.63 49.0779 210.338 46.255 210.338H5.39038C2.56746 210.338 0.281006 212.63 0.281006 215.448C0.281006 255.984 31.9164 289.26 71.7966 291.901V302.286C71.7966 304.349 73.0362 306.213 74.9477 307.005C75.5831 307.265 76.2445 307.396 76.906 307.396C78.2341 307.396 79.5414 306.875 80.5154 305.901L111.166 275.25C113.161 273.25 113.161 270.026 111.166 268.026L80.5154 237.38ZM82.0102 289.958V286.963C82.0102 284.14 79.7289 281.854 76.906 281.854C42.005 281.854 13.3123 254.797 10.6925 220.557H41.1456V225.667C41.1456 245.38 57.1925 261.422 76.906 261.422C79.7289 261.422 82.0102 259.135 82.0102 256.312V253.323L100.328 271.64L82.0102 289.958Z"
          fill="white"
        />
      </g>
      <path d="M25.823 21.3389H36.0417V31.5576H25.823V21.3389Z" fill="white" />
      <path
        d="M46.2551 21.3389H56.4739V31.5576H46.2551V21.3389Z"
        fill="white"
      />
      <path
        d="M66.6875 21.3389H76.9063V31.5576H66.6875V21.3389Z"
        fill="white"
      />
      <path d="M25.823 46.8799H36.0417V57.0934H25.823V46.8799Z" fill="white" />
      <path
        d="M46.2551 46.8799H56.4739V57.0934H46.2551V46.8799Z"
        fill="white"
      />
      <path
        d="M66.6875 46.8799H76.9063V57.0934H66.6875V46.8799Z"
        fill="white"
      />
      <path d="M25.823 72.4219H36.0417V82.6354H25.823V72.4219Z" fill="white" />
      <path
        d="M46.2551 72.4219H56.4739V82.6354H46.2551V72.4219Z"
        fill="white"
      />
      <path
        d="M66.6875 72.4219H76.9063V82.6354H66.6875V72.4219Z"
        fill="white"
      />
      <path d="M25.823 97.9639H36.0417V108.177H25.823V97.9639Z" fill="white" />
      <path
        d="M46.2551 97.9639H56.4739V108.177H46.2551V97.9639Z"
        fill="white"
      />
      <path
        d="M66.6875 97.9639H76.9063V108.177H66.6875V97.9639Z"
        fill="white"
      />
      <path d="M112.661 72.4219H122.88V82.6354H112.661V72.4219Z" fill="white" />
      <path
        d="M133.094 72.4219H143.313V82.6354H133.094V72.4219Z"
        fill="white"
      />
      <path
        d="M153.526 72.4219H163.745V82.6354H153.526V72.4219Z"
        fill="white"
      />
      <path d="M112.661 97.9639H122.88V108.177H112.661V97.9639Z" fill="white" />
      <path
        d="M133.094 97.9639H143.313V108.177H133.094V97.9639Z"
        fill="white"
      />
      <path
        d="M153.526 97.9639H163.745V108.177H153.526V97.9639Z"
        fill="white"
      />
      <path d="M112.661 123.5H122.88V133.719H112.661V123.5Z" fill="white" />
      <path d="M133.094 123.5H143.313V133.719H133.094V123.5Z" fill="white" />
      <path d="M153.526 123.5H163.745V133.719H153.526V123.5Z" fill="white" />
      <mask
        id="mask1_8_239"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="193"
        y="0"
        width="115"
        height="99"
      >
        <path
          d="M193.333 0.395508H307.026V98.333H193.333V0.395508Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_8_239)">
        <path
          d="M235.255 16.4006V6.01516C235.255 3.95266 234.016 2.08808 232.104 1.29641C230.198 0.504746 228 0.942246 226.536 2.40058L195.891 33.0516C193.891 35.0464 193.891 38.2756 195.891 40.2756L226.536 70.9214C228.005 72.3902 230.198 72.8225 232.104 72.0308C234.016 71.2391 235.255 69.3745 235.255 67.312V57.6089C246.901 59.9839 255.688 70.3068 255.688 82.635V92.8537C255.688 95.6714 257.974 97.9631 260.797 97.9631H301.661C304.49 97.9631 306.771 95.6714 306.771 92.8537C306.771 52.3173 275.135 19.0412 235.255 16.4006ZM265.906 87.7443V82.635C265.906 62.9162 249.859 46.8797 230.151 46.8797C227.323 46.8797 225.042 49.1662 225.042 51.9891V54.9787L206.724 36.661L225.042 18.3433V21.3381C225.042 24.1558 227.323 26.4475 230.151 26.4475C265.047 26.4475 293.74 53.5048 296.359 87.7443H265.906Z"
          fill="white"
        />
      </g>
      <mask
        id="mask2_8_239"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="308"
        height="308"
      >
        <path
          d="M0.0258789 0.395508H307.026V307.396H0.0258789V0.395508Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_8_239)">
        <path
          d="M301.661 123.5H209.719C206.89 123.5 204.609 125.791 204.609 128.609V174.583H184.177V57.0933C184.177 54.2756 181.89 51.9891 179.067 51.9891H102.448V6.01514C102.448 3.19222 100.161 0.905762 97.3383 0.905762H5.39038C2.56746 0.905762 0.281006 3.19222 0.281006 6.01514V179.692C0.281006 182.51 2.56746 184.802 5.39038 184.802H122.88V302.286C122.88 305.109 125.161 307.395 127.984 307.395H301.661C304.489 307.395 306.771 305.109 306.771 302.286V128.609C306.771 125.791 304.489 123.5 301.661 123.5ZM66.6873 174.583H36.0414V133.718H66.6873V174.583ZM92.2289 174.583H76.906V128.609C76.906 125.791 74.6196 123.5 71.7966 123.5H30.932C28.1039 123.5 25.8227 125.791 25.8227 128.609V174.583H10.4998V11.1245H92.2289V174.583ZM173.958 62.2026V174.583H102.448V62.2026H173.958ZM133.094 184.802H204.609V297.177H133.094V184.802ZM271.015 297.177H260.797V256.312H271.015V297.177ZM250.583 297.177H240.364V256.312H250.583V297.177ZM296.557 297.177H281.229V251.203C281.229 248.385 278.948 246.098 276.125 246.098H235.255C232.432 246.098 230.151 248.385 230.151 251.203V297.177H214.823V133.718H296.557V297.177Z"
          fill="white"
        />
      </g>
      <path
        d="M271.016 220.558H281.229V230.771H271.016V220.558Z"
        fill="white"
      />
      <path
        d="M250.583 220.558H260.797V230.771H250.583V220.558Z"
        fill="white"
      />
      <path
        d="M230.151 220.558H240.365V230.771H230.151V220.558Z"
        fill="white"
      />
      <path
        d="M184.177 246.099H194.391V256.312H184.177V246.099Z"
        fill="white"
      />
      <path
        d="M163.745 246.099H173.958V256.312H163.745V246.099Z"
        fill="white"
      />
      <path
        d="M143.312 246.099H153.526V256.312H143.312V246.099Z"
        fill="white"
      />
      <path
        d="M143.312 195.016H194.391V205.234H143.312V195.016Z"
        fill="white"
      />
      <path
        d="M143.312 220.558H194.391V230.771H143.312V220.558Z"
        fill="white"
      />
      <path
        d="M225.042 195.016H286.339V205.234H225.042V195.016Z"
        fill="white"
      />
      <path
        d="M225.042 169.474H286.339V179.692H225.042V169.474Z"
        fill="white"
      />
      <path
        d="M225.042 143.933H286.339V154.151H225.042V143.933Z"
        fill="white"
      />
    </svg>
  )
}

export default IconB2B
