import React, { useEffect, useRef } from "react"
import styled, { css } from "styled-components"

import { useShowHideModalContext } from "../hooks/useShowHideModalContext"

import { Content } from "../core/LayoutComponents"
import { Description, Plan, SparkleHeader } from "../homepage/PricingStyling"

import { Cost, Dollar, Subtext } from "../core/PricingComponents"

import { FREE_PLAN, ANNUAL_PLAN_SUFFIX } from "../utils/constants"
import { formatMonthlyPrice } from "../utils/pricing"
import { usePricingPlansContext } from "../context/pricing"
import { navigate } from "gatsby"

const PricingCards = ({ hideSelectButtons = false, top = "10" }) => {
  const {
    plans,
    isAnnual,
    setIsAnnual,
    selectedPlan,
    selectedPlanColor,
    selectedPlanInactiveColor,
    setSelectedPlan,
  } = usePricingPlansContext()

  return (
    <Content top={top}>
      <PricingGrid>
        {plans.map((plan, i) => (
          <PricingGridItem key={i + plan.key} id={`grid-${plan.key}`}>
            <PricingCard
              id={plan.key}
              color={
                plan.key === selectedPlan
                  ? plan.accentColor
                  : plan.inactiveAccentColor
              }
              onClick={() => setSelectedPlan(plan.key)}
            >
              <CardContent id={`content-${plan.key}`}>
                <CardHeader>
                  <SparkleHeader color={plan.accentColor}>
                    Sparkle <div>{plan.name}</div>
                  </SparkleHeader>
                  {plan.key !== FREE_PLAN && (
                    <HideShowBlockContainer hideOnSmall>
                      <LineBreak color={"#FFF"} />
                      <Description color={plan.accentColor}>
                        {plan.description}
                      </Description>
                    </HideShowBlockContainer>
                  )}
                  <Cost>
                    <Dollar>
                      $
                      {isAnnual
                        ? formatMonthlyPrice(plan.price.annual, isAnnual)
                        : formatMonthlyPrice(plan.price.monthly)}
                    </Dollar>
                    <Subtext>
                      {plan.key === FREE_PLAN ? "forever!" : "per month"}
                    </Subtext>
                  </Cost>
                  {plan.key !== FREE_PLAN ? (
                    <Subtext color={plan.accentColor}>
                      {isAnnual
                        ? `($${plan.price.annual}/yr, billed annually)`
                        : plan.price.subtext}
                    </Subtext>
                  ) : null}
                  <HideShowBlockContainer hideOnLarge hide={hideSelectButtons}>
                    <Button
                      color={plan.accentColor}
                      highlightColor={plan.inactiveAccentColor}
                      onClick={() => navigate("/signup")}
                    >
                      Select
                    </Button>
                  </HideShowBlockContainer>
                </CardHeader>

                <CardBody>
                  {plan.key === FREE_PLAN && (
                    <HideShowBlockContainer hideOnSmall>
                      <Description color={plan.accentColor}>
                        {plan.description}
                      </Description>
                      <LineBreak color={"#FFF"} />
                    </HideShowBlockContainer>
                  )}
                  <HideShowBlockContainer hideOnSmall hide={hideSelectButtons}>
                    <Button
                      color={plan.accentColor}
                      highlightColor={plan.inactiveAccentColor}
                      onClick={() => navigate("/signup")}
                    >
                      Select
                    </Button>
                  </HideShowBlockContainer>
                </CardBody>

                <CardBody>
                  <FeatureList id={`features-${plan.key}`}>
                    {plan.key !== FREE_PLAN && (
                      <>
                        <FeatureItem color={plan.accentColor}>
                          {`All `}
                          <Highlight
                            color={plan.accentColor}
                            textTransform={"uppercase"}
                          >
                            {plans[i - 1].name} Plan
                          </Highlight>
                          {` features`}
                        </FeatureItem>

                        <Highlight
                          color={plan.accentColor}
                          style={{
                            textTransform: "uppercase",
                            display: "flex",
                            justifyContent: "center",
                            marginLeft: "-2.5rem",
                          }}
                        >
                          - PLUS -
                        </Highlight>
                      </>
                    )}
                    {plan.features.map((feature) => {
                      // switch case to determine how to render description
                      let innerHtmlDescription = ""
                      switch (true) {
                        case feature.showDescriptionOnly:
                          innerHtmlDescription = feature.description
                          break
                        case feature.maxLimit !== 0 && !!feature.preText:
                          innerHtmlDescription = (
                            <>
                              {`${feature.preText} `}
                              <Highlight color={plan.accentColor}>
                                {feature.maxLimit == null
                                  ? "unlimited"
                                  : feature.maxLimit}
                              </Highlight>
                              {` ${feature.description}`}
                            </>
                          )
                          break
                        case feature.maxLimit === 0:
                          return null
                        default:
                          innerHtmlDescription = (
                            <>
                              <Highlight color={plan.accentColor}>
                                {feature.maxLimit === null
                                  ? "Unlimited"
                                  : feature.maxLimit}{" "}
                              </Highlight>
                              {feature.description}{" "}
                            </>
                          )
                          break
                      }
                      return (
                        <FeatureItem color={plan.accentColor} key={feature.key}>
                          {innerHtmlDescription}
                        </FeatureItem>
                      )
                    })}
                  </FeatureList>
                </CardBody>
              </CardContent>
            </PricingCard>
          </PricingGridItem>
        ))}
      </PricingGrid>

      <MoreInfo>
        <small>
          * plus Stripe’s processing fee of 2.9% + 30¢ per successful card
          charge
        </small>
        <LineBreak color={"#FFF"} />
        <span style={{textAlign: "center"}}>
        If you are an economic development or business support organization, please book a{" "}
          <HighlightLink
            color="white"
            href="https://sparkl.es/wesparkle"
          >
            <u>discovery call</u>
          </HighlightLink>
           {" "}or email
          <HighlightLink
            color="white"
            href="mailto:hello@wesparkle.co">
                {" "}<u>hello@wesparkle.co</u>
            </HighlightLink>
            {" "}for enterprise pricing.
        </span>
      </MoreInfo>
    </Content>
  )
}

export default PricingCards

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  /* background-color: var(--sparkle-darkblue); */
  padding-block: 0;
  padding-inline: clamp(0.5rem, 2vw, 1rem);
`

const PricingGridItem = styled.div`
  grid-column: span 3;

  // set the order of the grid item with prop key of 'free' to last
  order: ${(props) => (props.id === `grid-${FREE_PLAN}` ? 4 : null)};

  @media screen and (min-width: 45em) {
    // place the FREE_PLAN grid item at the end & span across 3 columns on larger screens
    ${(props) => {
      switch (props.id) {
        case `grid-${FREE_PLAN}`:
          return css`
            grid-column: span 3;
            order: 4;
          `
        default:
          return css`
            grid-column: span 1;
            display: flex;
          `
      }
    }}
  }
`

const PricingCard = styled.div`
  padding: 0.5rem;
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 0.75rem;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
  & div {
    /* background-color: var(--sparkle-darkblue); */
    color: var(--sparkle-white);
  }
  :hover {
    // transform slowly
    transition: transform 0.25s;
    transform: translateY(-2.5px);
  }
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  text-align: left;
  /* max-width: 20ch; */
  > div {
    min-width: 12ch;
  }

  @media screen and (min-width: 45em) {
    text-align: center;
  }
`

const CardContent = styled.div`
  display: flex;

  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  padding-inline: clamp(0.5rem, 1vw, 1rem);
  padding-block: 0.5em;
  & > ${CardHeader} {
    padding-inline: 0.5em;
  }

  @media screen and (min-width: 45em) {
    ${(props) => {
      switch (props.id) {
        case `content-${FREE_PLAN}`:
          return css`
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
          `
        default:
          return css`
            flex-direction: column;
            align-items: center;
          `
      }
    }}
  }
`

const CardBody = styled.div`
  text-align: center;
`
const LineBreak = styled.div`
  border-top: ${(props) => `1px solid ${props.color}`};
  width: 9ch;
  margin-inline: auto;
  margin-block: 0.75em;
`

const FeatureList = styled.ul`
  text-align: left;

  list-style-position: outside;
  padding-block: 0;
  margin-inline: 1em 0;
  font-size: clamp(0.85rem, 1.2vw, 1rem);
  letter-spacing: 0.025em;
  font-weight: 300;

  & h3 {
    margin-left: -1rem;
    margin-inline: auto;
    padding-block: 0.5em 0;
  }

  // if PricingGridItem spans 1 column, split the li into 2 columns
  @media screen and (min-width: 45em) {
    ${(props) => {
      switch (props.id) {
        case `features-${FREE_PLAN}`:
          return css`
            padding-left: 1rem;
            column-count: 2;
          `
        default:
          return css`
            column-count: 1;
          `
      }
    }}
  }
`

const FeatureItem = styled.li`
  line-height: 1.3;
  font-size: clamp(0.75rem, 0.35rem + 1vw, 1rem);

  padding-top: 0.65em;
  ::marker {
    content: "✓ ";
    color: ${(props) => props.color};
  }

  @media screen and (min-width: 45em) {
    font-size: clamp(0.75rem, 0.5em + 1vw, 1rem);
  }
`

const Button = styled.button`
  background-color: ${(props) => props.color};
  color: var(--sparkle-darkblue) !important;
  border: none;
  border-radius: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: clamp(0.85rem, 1.75vw, 1rem);
  padding: 0.25rem 2rem;
  margin-top: 0.5rem;

  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
  & :hover {
    cursor: pointer;
    filter: brightness(1.25);
    transition: filter 0.25s;
  }

  @media screen and (min-width: 45em) {
    margin: 0.5rem auto;
  }
`

const Highlight = styled.span`
  color: ${(props) => props.color};
  font-weight: 600;
  /* text-transform: ${(props) => props.textTransform}; */
  // if style prop is passed, apply as css
  ${(props) => props.style && props.style}
`

const HighlightLink = styled.a`
  color: ${(props) => props.color};
  & span {
    text-wrap: balance;
  }
`

const HideShowBlockContainer = styled.div`
  // if passed prop hide or hideOnSmall is true, hide on smaller screens, switch case
  display: ${(props) => (props.hide || props.hideOnSmall ? "none" : "block")};

  // if passed hide prop is true, hide on larger screens
  @media screen and (min-width: 45em) {
    display: ${(props) => (props.hide || props.hideOnLarge ? "none" : "block")};
  }
`

const MoreInfo = styled.div`
  margin-block: 0.5em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  small {
    font-size: 0.75em;
    font-weight: 300;
  }
  span {
    margin: 0.5em;
  }
`
