import React from "react"

const IconArts = (props) => {
  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>arts-and-crafts</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill="none" />
        </g>
        <g id="icons_Q2" data-name="icons Q2">
          <path d="M44.4,11.4,36.6,3.6a1.9,1.9,0,0,0-2.8,0L21.4,16l-2.8,2.8-2.8,2.8L13,24.4,3.6,33.8A2,2,0,0,0,3,35.2V43a2,2,0,0,0,2,2h7.8a2,2,0,0,0,1.4-.6L24.4,34.1l2.9-2.8,1-1,2.8-2.8L44.4,14.2A1.9,1.9,0,0,0,44.4,11.4ZM11.9,41H7V36.1L29.4,13.7l4.9,4.9ZM37.1,15.8l-4.9-4.9,3-3.1,5,5Z" />
          <g>
            <path d="M9,3A7,7,0,0,0,4.5,15.3l7.1,6.2,2.8-2.9L7.1,12.3A2.8,2.8,0,0,1,6,10.1a3.1,3.1,0,0,1,.9-2.2A2.9,2.9,0,0,1,9,7h.2a2.8,2.8,0,0,1,2.1,1l6.4,7.4,2.9-2.9-6.2-7A7.2,7.2,0,0,0,9.3,3Z" />
            <path d="M37.3,34.9a1.7,1.7,0,0,1,1.4.5c.4.4.5,1.1.4,2.7a14.6,14.6,0,0,0,.2,2.9A8.7,8.7,0,0,1,35,39.7,4,4,0,0,1,34,37v-.4l.5-.4.4-.5.9-.7h1.4M34,28.7l-6.2,6.1L30,36.9a7.8,7.8,0,0,0,2.3,5.8c2.3,1.9,5.8,2.3,8.6,2.3s3.8-.3,4.2-.3.9-.6.9-.8-2.3-1.3-2.8-3.6.7-5.5-1.8-7.8a5.7,5.7,0,0,0-4.1-1.6H36.1L34,28.7Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconArts
