import React from "react"

function getPlanFeaturesByProduct(features) {
  return features.reduce((featuresByProduct, feature) => {
    if (feature.maxLimit !== 0) {
      if (!featuresByProduct[feature.product]) {
        featuresByProduct[feature.product] = []
      }
      featuresByProduct[feature.product].push(feature.description)
    }

    return featuresByProduct
  }, {})
}

function getUniquePlanFeatureLists(plans) {
  // a set of features that have already been added to any plan
  const featureIncluded = new Set()

  const planFeatures = {}

  plans.forEach((plan) => {
    // add the plan key to the planFeatures object if it doesn't exist
    if (!planFeatures[plan.key]) {
      planFeatures[plan.key] = []
    }

    plan.features.forEach((feature) => {
      if (feature.maxLimit !== 0) {
        // only add feature if it hasn't already been added to another plan
        if (
          !planFeatures[plan.key].includes(feature.description) &&
          !featureIncluded.has(feature.description)
        ) {
          planFeatures[plan.key].push(feature.description)
          featureIncluded.add(feature.description)
        }
      }
    })
  })
  return planFeatures
}

function formatMonthlyPrice(price, isAnnual = false) {
  let calculatedPrice = price
  if (isAnnual) {
    // divide price by 12 to get monthly price
    calculatedPrice = price / 12
  }
  // if price is whole number, return as is, else return with decimal as superscript
  return calculatedPrice % 1 === 0 ? (
    calculatedPrice
  ) : (
    <>
      {calculatedPrice.toString().split(".")[0]}
      <sup>{calculatedPrice.toString().split(".")[1]}</sup>
    </>
  )
}

export {
  getPlanFeaturesByProduct,
  getUniquePlanFeatureLists,
  formatMonthlyPrice,
}
