import React from "react"
import styled from "styled-components"

const StickyHeader = styled.div`
  position: ${({ isSticky }) => (isSticky ? "sticky" : "static")};
  top: ${({ top }) => (top ? `${top}px` : 0)};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1)};
  box-shadow: rgba(0, 0, 0, 0.15) 0 4px 12px 0px;
  backdrop-filter: blur(15px);
  width: 100%;
`
export { StickyHeader }
