import React, { useState, useContext, createContext, useEffect } from "react"

export const SignUpContext = createContext([])
export const SignUpProvider = ({ children }) => {
  const [text, setText] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [businessType, setBusinessType] = useState("")

  // // TODO: DELETE THIS
  // useEffect(() => {
  //   console.log({text,firstName,lastName,email, businessType})
  // }, [text, firstName, lastName, email, businessType])

  return (
    <SignUpContext.Provider
      value={{
        text,
        setText,
        email,
        setEmail,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        businessType,
        setBusinessType,
      }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

const useSignUpContext = () => {
  const context = useContext(SignUpContext)
  if (context === undefined) {
    // Let's yell at ourselves to make sure we use our Provider wrapper
    throw new Error(
      "Oooops, I'm guessing your forgot to use the Provider for this context"
    )
  }
  return context
}

export default useSignUpContext
