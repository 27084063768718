import React, { createContext, useContext } from "react"
import useTenantInfo from "./useTenantInfo"

export const TenantInfoContext = createContext({})
export const TenantInfoProvider = ({ tenant, tenantUrl, children }) => {
  const [tenantInfo] = useTenantInfo({
    tenant,
    tenantUrl,
  })
  return (
    <TenantInfoContext.Provider
      value={(tenantInfo && tenantInfo?.tenantId ? tenantInfo : tenant) || {}}
    >
      {children}
    </TenantInfoContext.Provider>
  )
}
const useTenantInfoContext = () => {
  const context = useContext(TenantInfoContext)
  if (!context) {
    // Let's yell at ourselves to make sure we use our Provider wrapper
    throw new Error(
      "Oooops, I'm guessing your forgot to use the Provider for this context"
    )
  }
  return context
}

export default useTenantInfoContext
