import React from "react"

const IconCheck = ({
  height = 10,
  width = 5,
  thickness = 2,
  style = {},
  props,
}) => {
  return (
    <span
      style={{
        height,
        width,
        display: "inline-block",
        transform: "rotate(45deg)",
        borderBottom: `${thickness}px solid currentColor`,
        borderRight: `${thickness}px solid currentColor`,
        ...style,
      }}
      {...props}
    />
  )
}

export default IconCheck
