import { useState, useEffect } from "react"

const browser = () => !!(typeof window !== "undefined" && window)

const useIsBrowser = () => {
  const [isBrowser, setBrowser] = useState(() => browser())
  useEffect(() => {
    if (isBrowser !== browser()) setBrowser(browser())
  }, [isBrowser])
  return isBrowser
}

export default useIsBrowser
