import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import IconLogo from "../icons/IconLogo"

const TenantLogo = ({
  tenantInfo: { featuredImg, tenantImageUrl, tenantName } = {},
}) => {
  const [error, setError] = useState(false)
  const newerImage = !featuredImg && tenantImageUrl
  const gatsbyImgData = featuredImg ? getImage(featuredImg) : undefined
  const olderImage = featuredImg && gatsbyImgData
  if (newerImage && !error) {
    return (
      <img
        onError={() => setError(true)}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        alt={tenantName}
        src={tenantImageUrl}
        loading="lazy"
      />
    )
  }
  if (olderImage && !error) {
    return (
      <div className={"pos-full"} style={{ overflow: "hidden" }}>
        <GatsbyImage
          className={"pos-full pos-center"}
          style={{
            borderRadius: "100%",
            overflow: "hidden", // Need here b/c Safari bug
            objectFit: "cover",
          }}
          alt={tenantName}
          backgroundColor={"#fff"}
          image={gatsbyImgData}
          loading="lazy"
        />
      </div>
    )
  }
  // Error or image does not exist
  return (
    <div
      className={"pos-full"}
      style={{ background: "radial-gradient(#fff, var(--accent-opacity-20))" }}
    >
      <IconLogo
        onlyMark
        className={"pos-center"}
        style={{
          fill: "var(--accent)",
          width: "65%",
          height: "65%",
        }}
      />
    </div>
  )
}

export default TenantLogo
