import axios from "axios"
import queryString from "query-string"

import {
  ANNUAL_PLAN_SUFFIX,
  FREE_PLAN,
  LEGACY_FREE_PLAN,
  PREMIUM_PLAN,
  PRO_PLAN,
  STARTER_PLAN,
} from "../utils/constants"

const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

// MORE UTILS for clicking and starting site :)... not really a hook
const onPaidPlanHandler = ({
  planCode,
  user,
  setLoadingPurchaseUrl,
  text,
  queryStringParsed,
  email,
  firstName,
  lastName,
  businessType,
}) => {
  const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
  api
    .post(`GetSubscriptionLinks?code=${apiCode}`, {
      productCode: planCode,
      tenantId: user ? user["https://wesparkle.biz/sparkleTenantId"] : null,
      userId: user ? user.sub : null,
      siteName: text || "",
      queryStrings: queryStringParsed, // For passing along info like like utm codes for tracking landing pages + campaigns
      email,
      firstName,
      lastName,
      businessType,
    })
    .then((response) => {
      setLoadingPurchaseUrl(false)
      let purchaseUrl
      // Free purchase link
      if (response.data["FREE_PLAN"]) {
        purchaseUrl = response.data["FREE_PLAN"].hostedpage.url
      }
      // Starter purchase link
      if (response.data.STARTER) {
        purchaseUrl = response.data.STARTER.hostedpage.url
      }
      // Annual Starter purchase link
      if (response.data["STARTER-ANNUAL"]) {
        purchaseUrl = response.data["STARTER-ANNUAL"].hostedpage.url
      }
      // Basic purchase link
      if (response.data.BASIC) {
        purchaseUrl = response.data.BASIC.hostedpage.url
      }
      // Annual purchase link
      if (response.data["BASIC-ANNUAL"]) {
        purchaseUrl = response.data["BASIC-ANNUAL"].hostedpage.url
      }
      // Business purchase link
      if (response.data.BUSINESS) {
        purchaseUrl = response.data.BUSINESS.hostedpage.url
      }
      // Annual Business purchase link
      if (response.data["BUSINESS-ANNUAL"]) {
        purchaseUrl = response.data["BUSINESS-ANNUAL"].hostedpage.url
      }
      if (purchaseUrl && window) {
        window.location.assign(purchaseUrl)
      }
    })
    .catch((error) => {
      console.log(error)
      setLoadingPurchaseUrl(false)
    })
}

export const onSignUpClickStart = ({
  text,
  success,
  plan,
  monthly,
  setLoadingPurchaseUrl,
  user,
  email,
  firstName,
  lastName,
  businessType,
}) => {
  // console.log({
  //   text,
  //   email,
  //   firstName,
  //   lastName,
  //   businessType,
  //   monthly,
  //   plan,
  //   setLoadingPurchaseUrl,
  // })

  const validOrEmptySiteName = (text && success) || !text
  const qs = (window && window.location && window.location.search) || ""
  const queryStringParsed = queryString.parse(qs)
  if (validOrEmptySiteName && plan !== null && plan !== undefined) {
    switch (plan) {
      case FREE_PLAN: {
        // Free plan
        setLoadingPurchaseUrl(true)
        onPaidPlanHandler({
          planCode: monthly ? "FREE_PLAN" : "FREE_PLAN",
          user,
          setLoadingPurchaseUrl,
          queryStringParsed,
          text,
          email,
          firstName,
          lastName,
          businessType,
        })

        return
      }
      case STARTER_PLAN: {
        // Starter
        setLoadingPurchaseUrl(true)
        onPaidPlanHandler({
          planCode: monthly ? "STARTER" : "STARTER-ANNUAL",
          user,
          setLoadingPurchaseUrl,
          queryStringParsed,
          text,
          email,
          firstName,
          lastName,
          businessType,
        })

        return
      }
      case PRO_PLAN: {
        // Scheduler
        setLoadingPurchaseUrl(true)
        onPaidPlanHandler({
          planCode: monthly ? "BASIC" : "BASIC-ANNUAL",
          user,
          setLoadingPurchaseUrl,
          queryStringParsed,
          text,
          email,
          firstName,
          lastName,
          businessType,
        })

        return
      }
      case PREMIUM_PLAN: {
        // Assistant
        setLoadingPurchaseUrl(true)
        onPaidPlanHandler({
          planCode: monthly ? "BUSINESS" : "BUSINESS-ANNUAL",
          user,
          setLoadingPurchaseUrl,
          queryStringParsed,
          text,
          email,
          firstName,
          lastName,
          businessType,
        })

        return
      }

      default: {
        console.error("Type not allowed")
        return
      }
    }
  }
}
