import React from "react"
import styled, { keyframes } from "styled-components"

const FullPageContainer = styled.div`
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${({ dark }) => {
    if (dark)
      return `
      background: var(--sparkle-darkblue-gradient);
      color: var(--sparkle-white);
      `
  }}
`

const Section = styled.div`
  width: 100%;
  position: ${({ position }) => (position ? `${position}` : "relative")};

  ${({ dark }) => {
    if (dark)
      return `
        color: var(--sparkle-white);
        background: var(--sparkle-darkblue-gradient);
        background-size: 400% 400%;
        // animation: backgroundGradient 16s ease infinite;
    `
  }}
  @media screen and (max-width: 900) {
    // for the navbar but really should probably make this a variable / throw in a theme context provider
    top: 75px;
  }
`

const Content = styled.div`
  max-width: 65rem;
  margin: auto;
  padding-inline: clamp(0.5rem, 5vw, 1rem);
  padding-block: ${({ top }) => (top ? 0 : "clamp(0.5rem, 5vw, 1rem)")};
  margin-top: ${({ top }) => (top ? top + "px" : "0")};
`
const Main = styled.main`
  flex: 1 0 auto;
`

const Footer = styled.footer`
  flex-shrink: 0;

  ${({ dark }) => {
    if (dark)
      return `
        color: var(--sparkle-white);
        background: var(--sparkle-darkblue-gradient);
        background-size: 400% 400%;
        animation: backgroundGradient 16s ease infinite;
    `
  }}
`
const BackBtn = styled.button`
  font-size: 0rem;
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 45px;
  background: #fff;
  opacity: 0.75;
  border-radius: 100%;
  left: var(--content-pad);
  top: var(--content-pad);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

const backgroundGradient = keyframes`
    0% { background-position: 0% 99% }
    50% { background-position: 100% 2% }
    100% { background-position: 0% 99% }
`

export {
  BackBtn,
  FullPageContainer,
  Section,
  Content,
  Main,
  Footer,
  backgroundGradient,
}
