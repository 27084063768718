import React from "react"
import IconClose from "../icons/IconClose"
import IconLoaderCircle from "../icons/IconLoaderCircle"
import IconCheck from "../icons/IconCheck"

const SignUpIndicator = ({ error, success, loading }) => {
  const show = error || success || loading
  let background = "transparent"
  if (error) background = "var(--sparkle-red)"
  if (success) background = "var(--sparkle-green)"
  if (loading) background = "#fff"
  return (
    <span
      style={{
        opacity: show ? "1" : "0",
        transform: show ? "translateX(0)" : "translateX(5px)",
        transition: ".2s",
        background,
        cursor: error ? "pointer" : "",
        width: 34,
        height: 34,
        borderRadius: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      {/* Loading */}
      <div
        // Helper outter div for animations + placement
        className={"pos-full"}
        style={{
          display: "flex",
          opacity: loading ? 1 : 0,
          position: "absolute",
          transition: ".2s",
        }}
      >
        <IconLoaderCircle
          length={20}
          widthPercent={0.15}
          bg="#aaa"
          indicator="#000"
          style={{ margin: "auto" }}
        />
      </div>
      {/* Success */}
      <div
        // Helper outter div for animations + placement
        className={"pos-full"}
        style={{
          display: "flex",
          opacity: success ? 1 : 0,
          position: "absolute",
          transition: ".15s .2s",
          transform: success ? "translateY(0)" : "translateY(10px)",
        }}
      >
        <IconCheck
          height={15}
          width={8}
          thickness={3}
          style={{
            margin: "auto",
            color: "#fff",

            transition: "1s",
          }}
        />
      </div>
      <div
        // Helper outter div for animations + placement
        className={"pos-full"}
        style={{
          display: "flex",
          opacity: error ? 1 : 0,
          position: "absolute",
          transition: ".15s .2s",
          transform: error ? "translateY(0)" : "translateY(10px)",
        }}
      >
        <div
          style={{
            color: "#fff",
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "12px",
          }}
        >
          <IconClose length={3} />
        </div>
      </div>
    </span>
  )
}

export default SignUpIndicator
