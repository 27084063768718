import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { segmentAction } from "../hooks/useSegmentTrack"

import GlobalStyles from "../core/GlobalStyles"
import {
  BackBtn,
  Content,
  Footer,
  FullPageContainer,
  Main,
} from "../core/LayoutComponents"
import { ButtonPrimary } from "../homepage/Buttons"
import IconLoaderCircle from "../icons/IconLoaderCircle"

import NavBar from "./NavBar"
import FooterContent from "./FooterContent"
import PageModalPlan from "../homepage/PageModalPlan"
import PricingCards from "../pricing/PricingCards"
import SignUp from "../components/SignUp"
import { usePricingPlansContext } from "../context/pricing"
import { useShowHideModalContext } from "../hooks/useShowHideModalContext"

function Layout({ children }) {
  const { show, setShow } = useShowHideModalContext()
  const { selectedPlanIndex, isAnnual } = usePricingPlansContext()

  return (
    <FullPageContainer dark>
      <GlobalStyles />
      <NavBar
        links={[
          { name: "Solutions", href: "/#home-solutions-header" },
          { name: "Case Studies", href: "/case-studies"},
          { name: "Pricing", href: "/pricing" },
          { name: "About", href: "/about" },
          // {
          //   name: "Blog",
          //   href: "https://community.sprkl.es/portal/en/community/blog",
          //   target: "_blank",
          //   rel: "noopener noreferrer",
          // },
          { name: "Blog", href: "/blog" },
          { name: "Contact", href: "/contact" },
        ]}
      />
      <Main>{children}</Main>

      <Footer>
        <FooterContent id={"follow-for-more"} />
      </Footer>
    </FullPageContainer>
  )
}

export default Layout
