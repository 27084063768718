import React from "react"

const IconLogo = ({
  color,
  dots = true,
  onlyMark = false,
  markProps,
  ...props
}) => {
  const viewBox = onlyMark ? "0 0 109 109" : "0 0 545 109"
  return (
    <svg viewBox={viewBox} {...props}>
      <path d="M214.69 37.8449L204.021 77.2266H190.668L184.451 51.6779L178.022 77.2266H164.739L154 37.8449H166.081L171.663 66.005L178.304 37.8449H191.092L197.804 65.8638L203.315 37.8449H214.69Z" />
      <path d="M256.46 56.9006C256.46 58.0298 256.389 59.2061 256.248 60.4294H228.906C229.094 62.8761 229.871 64.7581 231.237 66.0755C232.65 67.3459 234.369 67.9811 236.395 67.9811C239.409 67.9811 241.505 66.7107 242.683 64.17H255.541C254.882 66.7578 253.681 69.0868 251.938 71.1571C250.242 73.2273 248.099 74.8506 245.509 76.0268C242.918 77.2031 240.022 77.7912 236.819 77.7912C232.956 77.7912 229.518 76.9679 226.503 75.3211C223.489 73.6743 221.134 71.3217 219.438 68.2634C217.743 65.2051 216.895 61.6292 216.895 57.5358C216.895 53.4423 217.719 49.8665 219.368 46.8081C221.063 43.7498 223.418 41.3973 226.433 39.7505C229.447 38.1037 232.909 37.2803 236.819 37.2803C240.634 37.2803 244.025 38.0802 246.992 39.6799C249.96 41.2797 252.268 43.5616 253.916 46.5258C255.612 49.4901 256.46 52.9483 256.46 56.9006ZM244.096 53.7247C244.096 51.6544 243.389 50.0076 241.976 48.7843C240.563 47.561 238.797 46.9493 236.677 46.9493C234.652 46.9493 232.933 47.5374 231.52 48.7137C230.154 49.89 229.306 51.5603 228.976 53.7247H244.096Z" />
      <path d="M293.843 77.7912C290.405 77.7912 287.343 77.2031 284.659 76.0268C281.974 74.8506 279.854 73.2508 278.3 71.2276C276.746 69.1574 275.874 66.8519 275.686 64.3111H287.626C287.767 65.6756 288.403 66.7813 289.534 67.6282C290.664 68.4751 292.053 68.8986 293.702 68.8986C295.209 68.8986 296.363 68.6163 297.164 68.0517C298.012 67.44 298.436 66.6637 298.436 65.7227C298.436 64.5934 297.847 63.77 296.669 63.2525C295.492 62.6879 293.584 62.0762 290.947 61.4175C288.121 60.7588 285.766 60.0765 283.881 59.3708C281.997 58.618 280.372 57.4652 279.007 55.9125C277.641 54.3128 276.958 52.172 276.958 49.4901C276.958 47.2316 277.57 45.1849 278.795 43.3499C280.066 41.4679 281.903 39.9857 284.305 38.9036C286.755 37.8214 289.651 37.2803 292.996 37.2803C297.941 37.2803 301.827 38.5036 304.653 40.9503C307.526 43.397 309.175 46.6435 309.599 50.6899H298.436C298.247 49.3254 297.635 48.2432 296.599 47.4433C295.61 46.6435 294.291 46.2435 292.642 46.2435C291.229 46.2435 290.146 46.5258 289.392 47.0905C288.639 47.608 288.262 48.3373 288.262 49.2783C288.262 50.4075 288.851 51.2545 290.028 51.8191C291.253 52.3837 293.137 52.9483 295.68 53.5129C298.601 54.2657 300.979 55.0185 302.816 55.7714C304.653 56.4771 306.254 57.6534 307.62 59.3002C309.033 60.8999 309.764 63.0643 309.811 65.7932C309.811 68.0987 309.151 70.169 307.832 72.004C306.561 73.7919 304.7 75.2034 302.251 76.2386C299.849 77.2737 297.046 77.7912 293.843 77.7912Z" />
      <path d="M329.283 43.4205C330.461 41.5855 332.086 40.1034 334.158 38.9742C336.231 37.8449 338.657 37.2803 341.436 37.2803C344.685 37.2803 347.629 38.1037 350.267 39.7505C352.905 41.3973 354.977 43.7498 356.484 46.8081C358.039 49.8665 358.816 53.4188 358.816 57.4652C358.816 61.5116 358.039 65.0875 356.484 68.1928C354.977 71.2512 352.905 73.6272 350.267 75.3211C347.629 76.9679 344.685 77.7912 341.436 77.7912C338.704 77.7912 336.278 77.2266 334.158 76.0974C332.086 74.9682 330.461 73.5096 329.283 71.7217V96H317.202V37.8449H329.283V43.4205ZM346.522 57.4652C346.522 54.4539 345.675 52.1014 343.979 50.4076C342.33 48.6667 340.282 47.7962 337.832 47.7962C335.43 47.7962 333.381 48.6667 331.686 50.4076C330.037 52.1484 329.213 54.5245 329.213 57.5358C329.213 60.547 330.037 62.9231 331.686 64.664C333.381 66.4049 335.43 67.2753 337.832 67.2753C340.234 67.2753 342.283 66.4049 343.979 64.664C345.675 62.8761 346.522 60.4765 346.522 57.4652Z" />
      <path d="M362.752 57.4652C362.752 53.4188 363.505 49.8665 365.013 46.8081C366.567 43.7498 368.663 41.3973 371.301 39.7505C373.938 38.1037 376.882 37.2803 380.132 37.2803C382.911 37.2803 385.337 37.8449 387.409 38.9742C389.529 40.1034 391.154 41.5855 392.284 43.4205V37.8449H404.366V77.2266H392.284V71.6511C391.107 73.4861 389.458 74.9682 387.339 76.0974C385.266 77.2266 382.84 77.7912 380.062 77.7912C376.859 77.7912 373.938 76.9679 371.301 75.3211C368.663 73.6272 366.567 71.2512 365.013 68.1928C363.505 65.0875 362.752 61.5116 362.752 57.4652ZM392.284 57.5358C392.284 54.5245 391.436 52.1484 389.741 50.4076C388.092 48.6667 386.067 47.7962 383.665 47.7962C381.263 47.7962 379.214 48.6667 377.518 50.4076C375.87 52.1014 375.045 54.4539 375.045 57.4652C375.045 60.4765 375.87 62.8761 377.518 64.664C379.214 66.4049 381.263 67.2753 383.665 67.2753C386.067 67.2753 388.092 66.4049 389.741 64.664C391.436 62.9231 392.284 60.547 392.284 57.5358Z" />
      <path d="M425.188 44.4085C426.601 42.2442 428.367 40.5504 430.486 39.327C432.606 38.0567 434.961 37.4215 437.552 37.4215V50.1958H434.231C431.216 50.1958 428.956 50.8545 427.448 52.172C425.941 53.4423 425.188 55.7008 425.188 58.9473V77.2266H413.106V37.8449H425.188V44.4085Z" />
      <path d="M467.418 77.2266L455.408 60.7117V77.2266H443.326V25H455.408V53.8658L467.348 37.8449H482.255L465.864 57.6064L482.397 77.2266H467.418Z" />
      <path d="M499.082 25V77.2266H487.001V25H499.082Z" />
      <path d="M545 56.9006C545 58.0298 544.929 59.2061 544.788 60.4294H517.446C517.634 62.8761 518.411 64.7581 519.777 66.0755C521.19 67.3459 522.91 67.9811 524.935 67.9811C527.949 67.9811 530.045 66.7107 531.223 64.17H544.082C543.422 66.7578 542.221 69.0868 540.478 71.1571C538.783 73.2273 536.64 74.8506 534.049 76.0268C531.458 77.2031 528.562 77.7912 525.359 77.7912C521.497 77.7912 518.058 76.9679 515.044 75.3211C512.029 73.6743 509.674 71.3217 507.979 68.2634C506.283 65.2051 505.435 61.6292 505.435 57.5358C505.435 53.4423 506.259 49.8665 507.908 46.8081C509.604 43.7498 511.959 41.3973 514.973 39.7505C517.987 38.1037 521.449 37.2803 525.359 37.2803C529.174 37.2803 532.565 38.0802 535.533 39.6799C538.5 41.2797 540.808 43.5616 542.457 46.5258C544.152 49.4901 545 52.9483 545 56.9006ZM532.636 53.7247C532.636 51.6544 531.929 50.0076 530.516 48.7843C529.103 47.561 527.337 46.9493 525.218 46.9493C523.192 46.9493 521.473 47.5374 520.06 48.7137C518.694 49.89 517.846 51.5603 517.517 53.7247H532.636Z" />
      <g {...markProps}>
        <path d="M75.8396 34.1663L60.7163 52.1666C62.7179 52.3888 67.1659 52.6111 71.614 52.6111C81.1772 52.6111 92.9644 51.9444 99.6365 47.9443C116.984 37.4997 107.421 14.8326 87.8492 20.8327C94.0765 1.27676 71.1692 -8.27897 60.9387 9.05469C56.9355 15.7215 56.7131 27.055 56.7131 36.6108C56.7131 41.722 56.9355 46.611 56.9355 48.611L75.1724 33.4996C75.6172 32.8329 76.2844 33.4996 75.8396 34.1663Z" />
        <path d="M32.9162 74.6114L48.4843 56.8333C46.7051 56.8333 41.5899 56.6111 36.4746 56.6111C26.9114 56.6111 15.569 57.0555 8.89693 60.8334C-8.22794 71.0558 1.3353 93.9451 20.9066 87.7228C14.6794 107.279 37.5866 116.834 48.0395 99.5008C52.0427 92.834 52.7099 81.056 52.7099 71.5002C52.7099 66.8335 52.2651 62.389 52.2651 60.3889L33.8058 75.0559C33.1386 75.7225 32.4714 75.0559 32.9162 74.6114Z" />
        <path d="M100.304 60.8334C93.6316 56.8333 82.2892 56.6111 72.726 56.6111C67.8331 56.6111 62.9403 56.8333 60.9387 56.8333L76.2844 74.6114C76.7292 75.0559 76.062 75.9448 75.3948 75.5003L56.9355 60.6111C56.7131 62.6112 56.4907 67.0557 56.4907 71.9447C56.4907 81.5004 57.1579 93.2784 61.1611 99.9452C71.614 117.279 94.2989 107.723 88.294 88.1672C107.865 93.9451 117.651 71.0558 100.304 60.8334Z" />
        <path d="M34.473 34.3885L52.4875 48.3888C52.4875 46.3887 52.7099 41.4998 52.7099 36.6108C52.7099 27.055 52.2651 15.7215 48.4843 9.05469C38.0315 -8.27897 15.1242 1.27676 21.3514 20.8327C1.78011 14.6104 -7.78313 37.4997 9.56414 47.9443C16.2362 51.9444 28.0234 52.6111 37.5867 52.6111C42.2571 52.6111 46.7051 52.1666 48.7067 52.1666L33.8058 35.2774C33.1386 34.6107 33.8058 33.9441 34.473 34.3885Z" />
        {dots && (
          <>
            <path d="M102.305 13.9437C104.307 11.9436 104.307 8.61022 102.305 6.61018C100.304 4.61014 96.9677 4.61014 94.9661 6.61018C92.9645 8.61022 92.9645 11.9436 94.9661 13.9437C96.9677 15.9437 100.304 15.9437 102.305 13.9437Z" />
            <path d="M6.89532 94.6118C4.89371 96.6119 4.89371 99.9453 6.89532 101.945C8.89693 103.945 12.2329 103.945 14.2345 101.945C16.2362 99.9453 16.2362 96.6119 14.2345 94.6118C12.2329 92.6118 8.89693 92.6118 6.89532 94.6118Z" />
            <path d="M94.9661 94.6118C92.9645 96.6119 92.9645 99.9453 94.9661 101.945C96.9677 103.945 100.304 103.945 102.305 101.945C104.307 99.9453 104.307 96.6119 102.305 94.6118C100.304 92.6118 96.9677 92.6118 94.9661 94.6118Z" />
            <path d="M14.2345 13.9437C16.2362 11.9436 16.2362 8.61022 14.2345 6.61018C12.2329 4.61014 8.89693 4.61014 6.89532 6.61018C4.89371 8.61022 4.89371 11.9436 6.89532 13.9437C8.89693 15.9437 12.2329 15.9437 14.2345 13.9437Z" />
          </>
        )}
      </g>
    </svg>
  )
}

export default IconLogo
