import React from "react"

const DefaultSvg = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
  </svg>
)

const FacebookSvg = props => (
  <svg viewBox="16 12 11 23" {...props}>
    <path d="M23.3147057,35 L18.4372418,35 L18.4372418,23.4985483 L16,23.4985483 L16,19.535534 L18.4372418,19.535534 L18.4372418,17.1562737 C18.4372418,13.9234411 19.8127074,12 23.7230238,12 L26.9776468,12 L26.9776468,15.964466 L24.9435074,15.964466 C23.4212558,15.964466 23.3206665,16.5182719 23.3206665,17.5518493 L23.3139606,19.535534 L27,19.535534 L26.5685836,23.4985483 L23.3139606,23.4985483 L23.3139606,35 L23.3147057,35 Z" />
  </svg>
)

const InstagramSvg = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
  </svg>
)

const LinkedinSvg = props => (
  <svg {...props} viewBox="12 12 20 20">
    <path d="M12.5 18.41h3.9V32h-3.9V18.4Zm1.85-1.7h-.02A2.25 2.25 0 0 1 12 14.36c0-1.34.94-2.36 2.38-2.36s2.32 1.02 2.35 2.36c0 1.31-.91 2.35-2.38 2.35ZM32 32h-4.42v-7.03c0-1.84-.7-3.1-2.21-3.1-1.17 0-1.81.85-2.11 1.67-.12.29-.1.7-.1 1.1V32h-4.38s.06-12.45 0-13.59h4.38v2.14c.26-.94 1.66-2.27 3.9-2.27 2.76 0 4.94 1.96 4.94 6.16V32Z" />
  </svg>
)

// via https://simpleicons.org/
const PinterestSvg = props => (
  <svg
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Pinterest</title>
    <path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z" />
  </svg>
)

// via https://simpleicons.org/
const TiktokSvg = props => (
  <svg
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>TikTok</title>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z" />
  </svg>
)

const TwitterSvg = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
  </svg>
)

const YouTubeSvg = props => (
  <svg viewBox="10.92 9.81 22.1 25.2" {...props}>
    <path
      fill="inherit"
      d="M16 28v-4h1.5V22.6H13v1.2h1.4V32H16v-4Zm12.5-.3v-1.1c.2-.3.4-.5.7-.5.3 0 .4.1.6.3v1.4H28.6Zm1.2 4.4c.7-.2 1.1-.6 1.3-1.2v-1.2H30v.4c0 .5-.1.7-.3.8-.2.2-.5.2-.8 0-.2-.1-.3-.4-.3-1.4v-.7h2.6v-1c0-1.2 0-1.4-.3-1.8-.3-.8-1.4-1.2-2.2-.9-.7.3-1 .7-1.2 1.3v2.2c0 2 0 2.2.2 2.6.3.7 1.3 1.1 2.1.9Zm-5-1.2.2-.2v-4.2c-.2-.4-.6-.4-1-.1v4.4c.3.2.6.2.8.1Zm.8 1.2c.2 0 .4-.3.5-.5.2-.5.2-.5.2-3V26c-.2-.7-.6-1-1.2-1-.3 0-.6.2-1 .5l-.2.3v-3.1h-1.2V32h1.2v-.6s.2 0 .3.2c.5.4.9.6 1.4.4Zm-6.4 0 .8-.5c0-.2.2-.2.2-.2v.7h1.2v-7h-1.2v5.4l-.2.2c-.2.3-.3.3-.4.3H19V25h-1.2v3l.1 3.7c.2.4.7.5 1.2.3Zm.5 2.9a147.4 147.4 0 0 1-6.3-.3c-.4 0-1-.4-1.2-.8-.5-.6-.8-1.3-.9-2.3-.3-2.6-.3-5.5 0-8 .2-1 .5-1.8 1-2.4a2 2 0 0 1 1-.6 109.5 109.5 0 0 1 17.2 0c.9 0 1.5.5 2 1.4a31 31 0 0 1 .4 10.2c-.3.9-.7 1.7-1.2 2-.4.4-.8.5-1.5.6h-.6a151.3 151.3 0 0 1-9.9.2Zm-2-15.5c-.1 0-.1-2.8 0-3.2a47.6 47.6 0 0 1-.9-3.3l-.9-3h1.5l.5 1.8.4 1.7.4-1.2.6-2.3h1.5v.1l-1 3.2-.3 1-.3 1-.1.3v4l-1.5-.1Zm8.7.1c-.4 0-.6-.2-.7-.4-.2-.4-.2-.4-.2-3.7v-3h1.4v2.8c0 3 0 3 .2 3 .2.1.3 0 .6-.2l.2-.3v-5.4h1.4v7.1h-1.3l-.1-.3v-.3l-.2.1c-.4.5-.9.7-1.3.6Zm-3.4-1.3c.2-.2.2-.2.2-2.2v-2c-.1-.3-.2-.5-.4-.5-.3-.1-.6 0-.7.4v4l.4.4.5-.1Zm-.6 1.3c-.6 0-1.1-.4-1.4-.9-.3-.5-.3-.8-.3-3l.1-2c.2-.5.5-1 1-1.2a2 2 0 0 1 2 .1c.3.3.6.7.7 1.2V16c0 2 0 2.1-.2 2.6a1.9 1.9 0 0 1-1.9 1Z"
    />
  </svg>
)

export const IconLinks = ({ name, ...props }) => {
  const formattedUrl = name.toLowerCase()
  switch (formattedUrl) {
    case "facebook":
      return <FacebookSvg {...props} />
    case "instagram":
      return <InstagramSvg {...props} />
    case "linkedin":
      return <LinkedinSvg {...props} />
    case "pinterest":
      return <PinterestSvg {...props} />
    case "tiktok":
      return <TiktokSvg {...props} />
    case "twitter":
      return <TwitterSvg {...props} />
    case "youtube":
      return <YouTubeSvg {...props} />
    default:
      return <DefaultSvg {...props} />
  }
}

const isSocial = name => {
  const formattedUrl = name.toLowerCase()
  switch (formattedUrl) {
    case "facebook":
    case "instagram":
    case "linkedin":
    case "pinterest":
    case "tiktok":
    case "twitter":
    case "youtube":
      return true
    default:
      return false
  }
}

export const parseLinks = links => {
  const social = []
  const regular = []
  ;(links || []).forEach(link => {
    if (isSocial(link.name)) {
      social.push(link)
    } else {
      regular.push(link)
    }
  })
  // console.log({ social, regular })
  return [social, regular]
}

export default IconLinks
